<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.88567 7.99985L15.6097 2.27585C16.131 1.75451 16.131 0.911845 15.6097 0.390512C15.0883 -0.130822 14.2457 -0.130822 13.7243 0.390512L8.00033 6.11451L2.27633 0.390512C1.755 -0.130822 0.912333 -0.130822 0.391 0.390512C-0.130333 0.911845 -0.130333 1.75451 0.391 2.27585L6.115 7.99985L0.391 13.7238C-0.130333 14.2452 -0.130333 15.0878 0.391 15.6092C0.651 15.8692 0.992333 15.9998 1.33367 15.9998C1.675 15.9998 2.01633 15.8692 2.27633 15.6092L8.00033 9.88518L13.7243 15.6092C13.9843 15.8692 14.3257 15.9998 14.667 15.9998C15.0083 15.9998 15.3497 15.8692 15.6097 15.6092C16.131 15.0878 16.131 14.2452 15.6097 13.7238L9.88567 7.99985Z"
      fill="#410000"
    />
  </svg>
</template>
