import { useFetch } from './useFetch';
import { useState } from './useState';

export const useBlockpass = ({ clientId, env, apiUrl, token, mainColor }) => {
  const { fetchData } = useFetch();
  // Get status for a single candidate by reference.
  const getBlockpassCandidate = async refId => {
    const { setState } = useState();

    const { result, loading, error } = fetchData({
      method: 'GET',
      url: `${apiUrl}/connect/${clientId}/refId/${refId}`,
      headers: {
        Authorization: token,
        'cache-control': 'no-cache',
      },
    });

    setState({
      blockpassLoading: loading,
      blockpassError: error,
    });

    const data = await result.value;

    if (data) {
      setState({
        blockpassStatus: data.data?.status || null,
      });

      return data;
    }

    return false;
  };

  // Blockpass widget
  const loadBlockpassWidget = async (refId, email) => {
    const blockpass = new window.BlockpassKYCConnect(
      clientId, // service client_id from the admin console
      {
        env,
        refId,
        mainColor,
        email,
      },
    );

    await blockpass.startKYCConnect();

    getBlockpassCandidate(refId);

    blockpass.on('KYCConnectSuccess', () => {
      getBlockpassCandidate(refId);
    });

    return blockpass;
  };

  return {
    loadBlockpassWidget,
    getBlockpassCandidate,
  };
};
