<template>
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6654 21.3947V23.333C21.6654 24.2547 20.9204 24.9997 19.9987 24.9997C19.077 24.9997 18.332 24.2547 18.332 23.333V19.9997C18.332 19.078 19.077 18.333 19.9987 18.333C21.377 18.333 22.4987 17.2113 22.4987 15.833C22.4987 14.4547 21.377 13.333 19.9987 13.333C18.6204 13.333 17.4987 14.4547 17.4987 15.833C17.4987 16.7547 16.7537 17.4997 15.832 17.4997C14.9104 17.4997 14.1654 16.7547 14.1654 15.833C14.1654 12.6163 16.782 9.99967 19.9987 9.99967C23.2154 9.99967 25.832 12.6163 25.832 15.833C25.832 18.4663 24.0654 20.6713 21.6654 21.3947ZM19.9987 29.9997C19.077 29.9997 18.332 29.2547 18.332 28.333C18.332 27.4113 19.077 26.6663 19.9987 26.6663C20.9204 26.6663 21.6654 27.4113 21.6654 28.333C21.6654 29.2547 20.9204 29.9997 19.9987 29.9997ZM19.9987 3.33301C10.8087 3.33301 3.33203 10.8097 3.33203 19.9997C3.33203 29.1897 10.8087 36.6663 19.9987 36.6663C29.1887 36.6663 36.6654 29.1897 36.6654 19.9997C36.6654 10.8097 29.1887 3.33301 19.9987 3.33301Z"
      fill="#410000"
    />
  </svg>
</template>
