<script>
import { computed } from 'vue';

export default {
  name: 'Button',
  props: {
    disabled: Boolean,
    href: String,
    target: String,
    variant: {
      type: String,
      value: [
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'ghost-primary',
        'ghost-secondary',
        'ghost-tertiary',
        'ghost-quaternary',
      ],
    },
    color: String,
    icon: String,
  },
  setup(props) {
    const classModifiers = computed(() => {
      if (props.variant) {
        return `button--${props.variant}`;
      }

      return false;
    });

    return {
      classModifiers,
    };
  },
};
</script>

<template>
  <a v-if="href" class="button" :class="classModifiers" :href="href" :target="target">
    <span class="button__text"><slot /></span>
    <span v-if="icon"><slot name="icon" /></span>
  </a>
  <button v-else class="button" :class="classModifiers" :disabled="disabled">
    <span class="button__text"><slot /></span>
  </button>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables.scss';

.button {
  background-color: $color-primary;
  border-radius: 4px;
  border: solid 2px $color-primary;
  box-shadow: none;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-s;
  font-weight: 500;
  height: 36px;
  line-height: 32px;
  padding: 0 1rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, color 0.25s ease-in-out;

  &:hover {
    border-color: darken($color-primary, 10%);
    background-color: darken($color-primary, 10%);
  }

  &:active {
    .button {
      &--ghost-quaternary {
        background-color: $color-secondary;
        border-color: $color-secondary;
      }
    }
  }

  &__text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14rem;
  }

  &--primary {
    background: $color-primary;
    border: solid 2px $color-primary;

    &:hover {
      border-color: darken($color-primary, 10%);
      background-color: darken($color-primary, 10%);
    }
  }

  &--secondary {
    background-color: $color-secondary;
    border-color: $color-secondary;

    &:hover {
      border-color: lighten($color-secondary, 5%);
      background-color: lighten($color-secondary, 5%);
    }
  }

  &--tertiary {
    background-color: #fff;
    border-color: #5283a3;
    color: #f9792f;

    &:hover {
      background-color: #fff;
    }
  }

  &--quaternary {
    background-color: #fff;
    border-color: #fff;
    color: $color-secondary;

    &:hover {
      background-color: #fff;
    }
  }

  &--ghost,
  &--ghost-primary {
    background-color: transparent;
    border-color: $color-primary;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &--ghost-secondary {
    background-color: transparent;
    border-color: $color-secondary;
    color: $color-secondary;

    &:hover {
      background-color: $color-secondary;
      color: #fff;
    }
  }

  &--ghost-tertiary {
    background-color: transparent;
    border-color: #5283a3;
    color: #5283a3;

    &:hover {
      background-color: #fff;
    }
  }

  &--ghost-quaternary {
    background-color: transparent;
    border-color: $color-white;
    color: $color-white;

    &:hover {
      background-color: $color-secondary;
      border-color: $color-secondary;
      color: $color-white;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: #bbb;
    border-color: #bbb;
    color: #fff;

    &:hover {
      background-color: #bbb;
      border-color: #bbb;
      color: #fff;
    }
  }

  @media screen and (min-width: $screen-size-m) {
    font-size: $font-size-m;
    height: 42px;
    line-height: 38px;
    padding: 0 1.5rem;
  }
}
</style>
