<script>
import { computed } from 'vue';

export default {
  name: 'Typography',
  props: {
    tag: {
      type: String,
      value: ['h1', 'h2', 'h3', 'h4', 'h5', 'span', 'p'],
      default: 'p',
    },
    lineHeight: {
      type: String,
      value: ['normal', 'special'],
    },
    size: {
      type: String,
      value: ['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl'],
    },
    weight: {
      type: String,
      value: ['normal', 'medium', 'semi-bold', 'bold'],
    },
    color: {
      type: String,
      value: ['primary', 'secondary', 'tertiary', 'quaternary'],
    },
  },
  setup(props) {
    const modifierClasses = computed(() => {
      let classes = '';
      if (props.color) classes += ` typography--c-${props.color}`;
      if (props.lineHeight) classes += ` typography--lh-${props.lineHeight}`;
      if (props.size) classes += ` typography--s-${props.size}`;
      if (props.weight) classes += ` typography--w-${props.weight}`;

      return classes;
    });

    return {
      modifierClasses,
    };
  },
};
</script>

<template>
  <component :is="tag" class="typography" :class="modifierClasses">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables.scss';

.typography {
  &--c-primary {
    color: $color-primary;
  }

  &--c-secondary {
    color: $color-secondary;
  }

  &--c-tieriary {
    color: $color-tertiary;
  }

  &--c-quaternary {
    color: $color-white;
  }

  &--lh-normal {
    line-height: $line-height-normal;
  }

  &--lh-special {
    line-height: $line-height-special;
  }

  &--w-normal {
    font-weight: 400;
  }

  &--w-medium {
    font-weight: 500;
  }

  &--w-semibold {
    font-weight: 600;
  }

  &--w-bold {
    font-weight: 700;
  }

  &--s-xxs {
    font-size: $font-size-xxs;
  }

  &--s-xs {
    font-size: $font-size-xs;
  }

  &--s-s {
    font-size: $font-size-s;
  }

  &--s-m {
    font-size: $font-size-m;
  }

  &--s-l {
    font-size: $font-size-l;
  }

  &--s-xl {
    font-size: $font-size-xl;
  }

  &--s-xxl {
    font-size: $font-size-xxl;
  }

  @media screen and (min-width: $screen-size-m) {
    &--s-xxs {
      font-size: $font-size-xs;
    }

    &--s-xs {
      font-size: $font-size-s;
    }

    &--s-s {
      font-size: $font-size-m;
    }

    &--s-m {
      font-size: $font-size-l;
    }

    &--s-l {
      font-size: $font-size-xl;
    }

    &--s-xl {
      font-size: $font-size-xxl;
    }

    &--s-xxl {
      font-size: $font-size-xxxl;
    }
  }
}
</style>
