<template>
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.9882 36.4578C34.4286 36.4578 36.407 34.4794 36.407 32.039C36.407 29.5985 34.4286 27.6201 31.9882 27.6201C29.5477 27.6201 27.5693 29.5985 27.5693 32.039C27.5693 34.4794 29.5477 36.4578 31.9882 36.4578Z"
      fill="currentColor"
    />
    <path
      d="M31.9875 54.6399C33.7927 54.6399 35.2562 53.1765 35.2562 51.3712C35.2562 49.566 33.7927 48.1025 31.9875 48.1025C30.1822 48.1025 28.7188 49.566 28.7188 51.3712C28.7188 53.1765 30.1822 54.6399 31.9875 54.6399Z"
      fill="currentColor"
    />
    <path
      d="M13.6109 25.204C14.3611 25.6289 15.2488 25.7401 16.0807 25.5135C16.9126 25.2869 17.6213 24.7408 18.0524 23.9941C18.4836 23.2474 18.6022 22.3607 18.3825 21.5269C18.1628 20.6931 17.6226 19.98 16.8796 19.5427C16.1293 19.1177 15.2416 19.0065 14.4097 19.2331C13.5778 19.4597 12.8691 20.0058 12.438 20.7525C12.0068 21.4992 11.8882 22.3859 12.1079 23.2197C12.3276 24.0535 12.8678 24.7666 13.6109 25.204Z"
      fill="currentColor"
    />
    <path
      d="M47.8835 25.5339C48.2979 25.6462 48.7304 25.6752 49.1561 25.6192C49.5818 25.5632 49.9922 25.4234 50.3635 25.2079C51.0188 24.8298 51.5227 24.2363 51.7894 23.5283C52.0561 22.8204 52.0691 22.0419 51.8261 21.3254C51.5831 20.609 51.0992 19.999 50.4569 19.5994C49.8145 19.1998 49.0535 19.0352 48.3034 19.1339C47.5533 19.2325 46.8607 19.5881 46.3435 20.1402C45.8263 20.6923 45.5165 21.4067 45.467 22.1616C45.4174 22.9165 45.6312 23.6652 46.0719 24.2801C46.5125 24.8951 47.1527 25.3382 47.8835 25.5339Z"
      fill="currentColor"
    />
    <path
      d="M26.672 18.2139L31.9876 15.1396L37.3032 18.2139V25.9014H42.6187V15.1475L31.9876 9L21.3564 15.1475V25.9014H26.672V18.2139Z"
      fill="currentColor"
    />
    <path
      d="M42.6185 25.9014L39.9607 30.5049L46.6179 34.3491L46.6228 40.4888L41.3033 43.5551L34.6451 39.7118L31.9873 44.3144L41.3013 49.6918L51.9404 43.5591L51.9315 31.2788L42.6185 25.9014Z"
      fill="currentColor"
    />
    <path
      d="M29.3304 39.7118L22.6722 43.5551L17.3527 40.4888L17.3566 34.3491L24.0148 30.5049L21.3571 25.9014L12.044 31.2788L12.0352 43.5591L22.6742 49.6918L31.9882 44.3144L29.3304 39.7118Z"
      fill="currentColor"
    />
    <circle cx="32" cy="32" r="30.5" stroke="currentColor" stroke-width="3" />
  </svg>
</template>
