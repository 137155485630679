<script>
import { onMounted, ref, nextTick } from 'vue';
import QRCodeStyling from 'qr-code-styling';
import CopyText from './CopyText';
import logo from '../assets/icons/logo-icon.svg';

export default {
  components: {
    CopyText,
  },
  props: {
    url: String,
    label: String,
  },
  setup(props) {
    const image = ref(null);

    const init = () => {
      nextTick(() => {
        const qrCode = new QRCodeStyling({
          width: 320,
          height: 320,
          data: props.url,
          image: logo,
          dotsOptions: {
            color: '#410000',
            type: 'rounded',
          },
          imageOptions: {
            margin: 5,
          },
        });

        // eslint-disable-next-line
        qrCode.append(image.value);
      });
    };

    onMounted(() => {
      init();
    });

    return {
      image,
    };
  },
};
</script>

<template>
  <div class="qrcode">
    <div ref="image" class="qrcode__image" />
    <div class="qrcode__box">
      <h2 class="qrcode__heading">Scan the QR code or copy the text below.</h2>
      <div>
        <span class="qrcode__label" v-if="label">{{ label }}:</span>
        <CopyText :text="url" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../assets/scss/variables';

.qrcode {
  background: $color-background;
  padding: 0.75rem;

  &__image {
    background-color: $color-background--secondary;
    border-radius: 4px;
    margin-bottom: 1rem;
    width: 100%;
  }

  &__heading {
    margin-bottom: 2rem;
  }

  canvas {
    width: 100%;
  }

  @media screen and (min-width: $screen-size-m) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__image {
      max-width: 250px;
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
}
</style>
