<script>
import { computed } from 'vue';

export default {
  props: {
    heading: String,
    expands: Boolean,
    variant: String,
  },
  setup(props, { slots }) {
    const headerSlot = slots.header;
    const contentSlot = slots.default;
    const footerSlot = slots.footer;

    const cssModifiers = computed(() => {
      if (props?.variant) {
        return `block--${props.variant}`;
      }

      return null;
    });

    return {
      cssModifiers,
      headerSlot,
      contentSlot,
      footerSlot,
    };
  },
};
</script>

<template>
  <div class="block" :class="cssModifiers">
    <header v-if="headerSlot" class="block__header">
      <slot name="header" />
    </header>
    <div v-if="contentSlot" class="block__body">
      <slot />
    </div>
    <footer v-if="footerSlot" class="block__footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<style lang="scss">
@import '../assets/scss/variables';
@import '../assets/scss/mixins';

.block {
  background-color: $color-white;
  border: solid 2px $color-white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 23px rgba(0, 0, 0, 0.06), 0 28px 92px rgba(0, 0, 0, 0.07);
  padding: 1rem;

  &__header {
    color: $color-primary;

    &::after {
      background-color: $color-grey--dark;
      content: '';
      display: block;
      height: 2px;
      margin: 2rem 0;
    }
  }

  &--orange {
    background-color: $color-primary;
    border-color: $color-primary;
    color: #fff;

    .block__header {
      color: $color-white;

      &::after {
        background-color: $color-white;
      }
    }

    a {
      color: $color-white;
      text-decoration: underline;
    }
  }

  &--transparent {
    background-color: transparent;
    color: #fff;
    border-radius: 0px;
    padding: 0.5rem;
    border: solid #fff 5px;
  }

  @media screen and (min-width: $screen-size-m) {
    padding: 2rem;
  }
}
</style>
