<script>
import { computed, ref, toRefs } from 'vue';
import moment from 'moment';
import { appConfig } from '../../../config';
import { useState } from '../../../composables/useState';
import { useFetch } from '../../../composables/useFetch';
import TokensBanner from '../TokensBanner';
import Accordion from '../../Accordion';
import Button from '../../Button.vue';
import Loader from '../../Loader';
import QrCode from '../../QrCode.vue';
import { useCountdown } from '../../../composables/useCountDown';
import CopyText from '../../CopyText';

export default {
  components: {
    CopyText,
    Loader,
    Accordion,
    Button,
    TokensBanner,
    QrCode,
  },
  setup() {
    const options = { ...appConfig };
    const { initCountdownTimer } = useCountdown();
    const { fetchData } = useFetch();
    const { state, setState } = useState();
    const status = ref({
      loading: false,
      error: null,
      completed: false,
    });

    const cardanoScanUrl =
      options.app.env === 'development'
        ? 'https://testnet.cardanoscan.io/transaction'
        : 'https://cardanoscan.io/transaction';

    const capitalizeString = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    /*
      STATUS: CREATED, PENDING, COMPLETE, FAILED, EXPIRED, INVALID
     */
    function reformatClaimStatus(claimStatus) {
      if (claimStatus === 'CREATED') {
        return 'Submitted';
      }

      if (claimStatus === 'PENDING') {
        return 'Processing';
      }

      if (claimStatus === 'COMPLETE') {
        return 'Complete';
      }

      if (claimStatus === 'FAILED' || claimStatus === 'EXPIRED') {
        return 'Incomplete';
      }

      if (claimStatus === 'INVALID') {
        return 'Invalid';
      }

      return capitalizeString(claimStatus);
    }

    // total tokens claimed
    const createdPendingCompletedClaims = computed(() => {
      return state.claims.filter(claim => ['COMPLETE', 'PENDING', 'CREATED'].includes(claim.status));
    });

    const hasClaimedAllTokens = computed(() => {
      return createdPendingCompletedClaims.value >= state.tokensPurchasedTotal;
    });

    const tokensClaimedTotal = computed(() => {
      return createdPendingCompletedClaims.value.reduce((a, b) => a + Number.parseFloat(b.amount), 0) || 0;
    });

    const hasClaimsInProgress = computed(() => {
      return state.claims.some(claim => claim.status === 'CREATED' || claim.status === 'PENDING');
    });

    const isAllowedToClaim = computed(() => {
      return false;
    });

    // Total Purchased Tokens x Number of Days Since 15/04/2022 / 184;
    const amountToClaim = computed(() => {
      const startDate = moment.utc('2022-03-15 12:00').valueOf();
      const currentDate = moment().utc().valueOf();
      const daysPassed = Math.ceil((currentDate - startDate) / 86400000);
      const totalDays = 184;
      const totalAmount = state.tokensPurchasedTotal;
      const amountAlreadyClaimed = tokensClaimedTotal.value;

      // Formula
      const amount = (totalAmount * daysPassed) / totalDays - amountAlreadyClaimed;

      if (totalDays > daysPassed) {
        return Math.round(amount);
      }

      if (hasClaimedAllTokens.value) {
        return 0;
      }

      return totalAmount - amountAlreadyClaimed;
    });

    const startCountdown = endTime => {
      return initCountdownTimer(moment.utc(endTime));
    };

    const handleClaimRequest = async event => {
      event.preventDefault();

      status.value.loading = false;
      status.value.error = null;

      const { purchaser_id: purchaserId, session_id: sessionId } = state;
      const form = event.target;
      const formData = new FormData(form);
      const transactionEndDateTime = () => moment().utc().add(6, 'hour');

      // convert to JSON
      const formDataObject = Array.from(formData.keys()).reduce((result, key) => {
        result[key] = formData.get(key);

        return result;
      }, {});

      const formBody = JSON.stringify({
        ...formDataObject,
        transaction_end_datetime: transactionEndDateTime(),
      });

      const { result, error, loading } = fetchData({
        url: `${options.api.purchaser}/${purchaserId}/claims`,
        method: 'POST',
        body: formBody,
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          'Content-Type': 'application/json',
        },
      });

      status.value.loading = loading;
      status.value.error = error;

      const data = await result.value;

      if (data) {
        setState({
          claims: [data, ...state.claims],
        });

        status.value.completed = true;
      }
    };

    return {
      ...toRefs(state),
      status,
      cardanoScanUrl,

      isAllowedToClaim,
      amountToClaim,
      hasClaimsInProgress,
      hasClaimedAllTokens,
      tokensClaimedTotal,

      reformatClaimStatus,
      startCountdown,
      handleClaimRequest,
    };
  },
};
</script>

<template>
  <div class="tokens-claim">
    <div class="tokens-claim__header">
      <div>
        <template v-if="!isAllowedToClaim">
          <p>
            <strong>
              Token claiming is disabled to allow unclaimed balances to be moved to the new Empowa Explorer consolidated
              claiming.
            </strong>
          </p>
          <p>
            Follow us on Twitter and Telegram for further updates, or contact us via our
            <a href="https://empowa-tech.atlassian.net/servicedesk/customer/portals" target="_blank">
              support portal
            </a>
            for further assistance.
          </p>
        </template>
        <template v-else>
          <p>
            Your EMP tokens can now be claimed ↓. The amount of tokens available to claim will vary, based on the number
            of days through the 6 months vesting period that have elapsed.
          </p>
          <p>
            Tokens can be claimed at any time (there are no fixed dates), however to manage dashboard load there are a
            limit of 6 claims per account.
          </p>
        </template>
        <hr />
      </div>
      <h3>Overview</h3>
      <TokensBanner title="Total Claimed EMP" :amount="tokensClaimedTotal" :subamount="tokensPurchasedTotal" />
    </div>
    <div class="tokens-claim__content">
      <div class="tokens-claim__group" style="margin-bottom: 2rem">
        <h3 class="tokens-claim__heading">You can claim: {{ amountToClaim }} tokens</h3>
        <form @submit="handleClaimRequest">
          <Button
            variant="secondary"
            class="tokens-purchase__add"
            :disabled="hasClaimsInProgress || hasClaimedAllTokens || +amountToClaim === 0 || !isAllowedToClaim"
          >
            <span v-if="!isAllowedToClaim">Not available</span>
            <span v-else-if="hasClaimedAllTokens">Already claimed</span>
            <span v-else-if="hasClaimsInProgress">Claim in progress</span>
            <span v-else-if="+amountToClaim === 0">Nothing to claim</span>
            <span v-else>Claim tokens</span>
          </Button>
          <input type="hidden" name="amount" :value="amountToClaim" />
          <input type="hidden" name="status" value="CREATED" />
        </form>
      </div>
      <Loader v-if="status.loading">Submitting request....</Loader>
      <h4>Claims History</h4>
      <template v-if="claims.length > 0">
        <ul class="tokens-claim__list">
          <li class="tokens-claim__item" v-for="item in claims" :key="item._id">
            <Accordion :is-active="item.status === 'CREATED'">
              <template v-slot:title>
                <span>Claim</span>
              </template>
              <template v-slot:subtitle>
                <span class="tokens-result__quantity">{{ item.amount }} EMP</span>
              </template>
              <template v-slot:status>
                <span>{{ reformatClaimStatus(item.status) }}</span>
              </template>
              <template v-slot:content>
                <template v-if="item.status === 'CREATED'">
                  <p>
                    To claim your EMP tokens please transfer exactly
                    <strong>2 ADA</strong> to the payment address below, prior to your purchase window expiring.
                  </p>
                  <p>
                    <strong>
                      We will send your claimed EMP plus 2 ADA (minus a transaction fee) to your linked wallet.
                    </strong>
                  </p>
                  <QrCode :url="empowa_wallet_addr" label="Payment Address" style="margin-bottom: 1rem" />
                  <p>
                    Your payment window expires in: <strong>{{ startCountdown(item.transaction_end_datetime) }}</strong>
                  </p>
                  <p>Once your payment has been received, the status of your token claim will be updated.</p>
                  <p>
                    <strong>
                      Note: Transactions may take several minutes to process. Follow the transaction progress on
                      <a href="https://cardanoscan.io" target="_blank">https://cardanoscan.io</a> using the payment
                      address.
                    </strong>
                  </p>
                </template>
                <template v-else-if="item.status === 'PENDING'">
                  <p><strong>Thank you!</strong></p>
                  <p>
                    Your 2 ADA has been received and the transfer of your EMP has been queued. This may take some time,
                    depending on current claiming demand and load on the Cardano blockchain.
                  </p>
                  <p>
                    If you have not received your EMP tokens within the next 3 hours, please contact our support team
                    using one of the links on the Token Sale page of our website at
                    <a href="https://empowa.io/token-sale" target="_blank">https://empowa.io/token-sale</a>.
                  </p>
                </template>
                <template v-else-if="item.status === 'COMPLETE'">
                  <p><strong>Thank you for completing your claim.</strong></p>
                  <p>
                    You have successfully claimed <strong>{{ item.amount }} EMP</strong> tokens.
                  </p>
                  <p>
                    Your transaction hash:
                    <CopyText :text="item.emp_transaction_hash" />
                  </p>
                  <p>
                    <Button :href="`${cardanoScanUrl}/${item.emp_transaction_hash}`" target="_blank"
                      >View on Cardanoscan</Button
                    >
                  </p>
                  <p v-if="item.comment">
                    <strong>NOTE: {{ item.comment }}</strong>
                  </p>
                </template>
                <template v-else-if="item.status === 'EXPIRED'">
                  <p><strong>Your token claim has not been processed.</strong></p>
                  <p>
                    Unfortunately we did not receive the 2 ADA required to transfer your EMP within claim window. Please
                    do NOT send any ADA now for this claim, as it will be returned. Submit a new claim to restart the
                    process.
                  </p>
                </template>
                <template v-else-if="item.status === 'INVALID'">
                  <p>
                    <strong>This has been identified as an invalid claim and can therefore not be processed.</strong>
                  </p>
                  <p>
                    If you believe this is incorrect, then please contact our Support Team using one of the links on the
                    token sale page of our website at
                    <a href="https://empowa.io/token-sale" target="_blank">https://empowa.io/token-sale</a>
                  </p>
                </template>
                <template v-else-if="item.status === 'FAILED'">
                  <p>
                    An incorrect amount of ADA was received, so your token claim has not been processed. Please submit a
                    new claim and send exactly 2 ADA.
                  </p>
                  <p>
                    To receive a refund of the ADA sent for this failed token claim, please contact our Support Team
                    using one of the links on the Token Sale page of our website at
                    <a href="https://empowa.io/token-sale/" target="_blank">https://empowa.io/token-sale/</a>, with the
                    transaction hash:
                  </p>
                  <CopyText :text="item.ada_transaction_hash" />
                </template>
                <template v-else>
                  <p>We can't determine the status of your claim. Please contact us at info@empowa.io.</p>
                </template>
              </template>
            </Accordion>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.tokens-claim {
  &__header {
    margin-bottom: 2rem;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  &__list {
    @include list-reset;
  }

  &__item {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 768px) {
    &__heading {
      margin: 0;
    }

    &__group {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
