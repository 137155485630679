<template>
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="30.5" stroke="currentColor" stroke-width="3" />
    <path
      d="M42.0069 14H21.9519C19.2283 14 17 16.2145 17 18.9211V42.5016C17 45.2082 19.2283 47.4227 21.9519 47.4227H22.8184C22.7359 47.0126 22.7359 46.5615 22.7359 46.1104C22.7359 45.6593 22.7772 45.2492 22.8184 44.7981H21.9519C20.6726 44.7981 19.5997 43.7729 19.5997 42.4606V18.9211C19.5997 17.6498 20.6314 16.6246 21.9519 16.6246H42.0069C43.2861 16.6246 44.3177 17.6498 44.3177 18.9211V42.5016C44.3177 43.7729 43.2861 44.8391 42.0069 44.8391H41.1403C41.2228 45.2492 41.2228 45.7003 41.2228 46.1514C41.2228 46.6025 41.2228 47.0126 41.1816 47.4227H42.0481C44.7717 47.4227 47 45.2082 47 42.5016V18.9211C46.9587 16.2145 44.7304 14 42.0069 14Z"
      fill="currentColor"
    />
    <path
      d="M38.8707 20.7256H25.0881C24.3453 20.7256 23.7676 21.2997 23.7676 22.0379C23.7676 22.7761 24.3453 23.3502 25.0881 23.3502H38.8295C39.5723 23.3502 40.15 22.7761 40.15 22.0379C40.1912 21.2997 39.5723 20.7256 38.8707 20.7256Z"
      fill="currentColor"
    />
    <path
      d="M38.8707 27.1641H25.0881C24.3453 27.1641 23.7676 27.7382 23.7676 28.4764C23.7676 29.2145 24.3453 29.7887 25.0881 29.7887H38.8295C39.5723 29.7887 40.15 29.2145 40.15 28.4764C40.1912 27.7382 39.5723 27.1641 38.8707 27.1641Z"
      fill="currentColor"
    />
    <path
      d="M38.8707 33.6025H25.0881C24.3453 33.6025 23.7676 34.1767 23.7676 34.9148C23.7676 35.653 24.3453 36.2271 25.0881 36.2271H38.8295C39.5723 36.2271 40.15 35.653 40.15 34.9148C40.1912 34.1767 39.5723 33.6025 38.8707 33.6025Z"
      fill="currentColor"
    />
    <path
      d="M31.9792 39.3027C28.1828 39.3027 25.0879 42.3784 25.0879 46.1513C25.0879 49.9242 28.1828 52.9999 31.9792 52.9999C35.7757 52.9999 38.8706 49.8832 38.8706 46.1103C38.8706 42.3374 35.7757 39.3027 31.9792 39.3027ZM35.1979 45.5362L31.8554 48.8579C31.6078 49.104 31.319 49.227 30.9889 49.227C30.6587 49.227 30.3699 49.104 30.1223 48.8579L28.4717 47.2176C28.0177 46.7665 28.0177 45.9873 28.4717 45.5362C28.9256 45.0851 29.7096 45.0851 30.1635 45.5362L30.9889 46.3564L33.4648 43.8958C33.9187 43.4447 34.7027 43.4447 35.1567 43.8958C35.6931 44.3059 35.6931 45.0851 35.1979 45.5362Z"
      fill="currentColor"
    />
  </svg>
</template>
