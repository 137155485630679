<script>
import { computed } from 'vue';

export default {
  props: {
    variant: {
      type: String,
      value: ['primary', 'secondary', 'tertiary', 'quaternary'],
    },
  },
  setup(props) {
    const classModifiers = computed(() => {
      if (props.variant) {
        return `loader--${props.variant}`;
      }

      return false;
    });

    return {
      classModifiers,
    };
  },
};
</script>

<template>
  <div class="loader" :class="classModifiers">
    <div class="loader__spinner"></div>
    <div class="loader__text">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.loader {
  align-items: center;
  display: flex;
  padding: 1rem 0;

  &__text {
    margin-left: 0.5rem;
  }

  &__spinner {
    display: inline-block;
    height: 32px;
    position: relative;
    width: 32px;

    &::before,
    &::after {
      background-color: $color-primary;
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.6;
      position: absolute;
      top: 0;
      width: 100%;

      animation: bounce 2s infinite ease-in-out;
      -webkit-animation: bounce 2s infinite ease-in-out;
    }

    &::after {
      animation-delay: -1s;
      -webkit-animation-delay: -1s;
    }
  }

  &--secondary {
    background-color: $color-secondary;
  }

  &--tertiary {
    background-color: $color-tertiary;
  }

  &--quaternary {
    .loader__spinner {
      &::before,
      &::after {
        background-color: $color-white;
      }
    }
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
