import { ref } from 'vue';

export const useUserWindow = () => {
  const isUserWindowActive = ref(true);

  document.addEventListener('visibilitychange', () => {
    isUserWindowActive.value = document.visibilityState === 'visible';
  });

  return {
    isUserWindowActive,
  };
};
