<script>
import { computed, ref } from 'vue';
import Loader from '../../Loader.vue';
import Button from '../../Button.vue';
import ArrowDownIcon from '../../icons/ArrowDownIcon';

export default {
  components: {
    Loader,
    Button,

    // Icons
    ArrowDownIcon,
  },
  props: {
    currentRound: Object,
    currentToken: Object,
    rounds: Array,
    error: Object,
    loading: Boolean,
    completed: Boolean,
    canSelectRound: {
      type: Boolean,
      default: true,
    },
    hasFcnft: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const selectedRound = ref(props.currentRound);

    const getRound = newRound => {
      return props.rounds.find(round => +round.roundNr === +newRound);
    };

    const handleChange = event => {
      event.preventDefault();

      selectedRound.value = getRound(event.target.value);
    };

    const minInput = computed(() => {
      if (selectedRound.value.max === 0) {
        return 0;
      }

      if (selectedRound.value.max > 0 && selectedRound.value.max < selectedRound.value.min) {
        return 1;
      }

      return selectedRound.value.min;
    });

    const requestValue = computed(() => props.currentToken?.quantity || selectedRound.value.max);

    return {
      minInput,
      requestValue,

      handleChange,
      selectedRound,
    };
  },
};
</script>

<template>
  <form class="tokens-form">
    <div v-if="canSelectRound" class="tokens-form__formgroup">
      <label for="request_round" class="tokens-form__label">Select Round</label>
      <div class="tokens-form__selectbox">
        <select class="tokens-form__select" id="request_round" name="round" @change="handleChange">
          <option v-for="round in rounds" :value="round.roundNr" :key="round.label">
            {{ round.label }}
          </option>
        </select>
        <ArrowDownIcon class="tokens-form__arrow" />
      </div>
    </div>
    <template v-if="selectedRound.max > 0">
      <div class="tokens-form__formgroup">
        <label for="request_quanity" class="tokens-form__label" style="margin-bottom: 0">Token amount</label>
        <p class="tokens-form__description">Minimum is {{ minInput }} and maximum is {{ selectedRound.max }}</p>
        <div class="tokens-form__inputgroup">
          <input
            id="request_quanity"
            class="tokens-form__input"
            name="quantity"
            type="number"
            :min="minInput"
            :max="selectedRound.max"
            :disabled="selectedRound.max <= 0"
            v-model="requestValue"
          />
          <input v-if="hasFcnft" type="hidden" name="round" :value="0" />
          <span class="tokens-form__inputlabel">EMP</span>
        </div>
      </div>
      <div class="tokens-form__formgroup">
        <Loader v-if="loading" class="tokens-form__loader">Sending Request...</Loader>
        <p v-if="error">{{ error }}</p>
        <div class="tokens-form__group">
          <Button class="tokens-form__submit">Submit</Button>
          <a href="#" class="tokens-form__cancel" @click="$emit('cancelForm', $event)">Cancel</a>
        </div>
      </div>
    </template>
    <template v-else>
      <p v-if="hasFcnft">
        Your maximum token allocation for this token sale has been reached. Thank you for participating.
      </p>
      <p v-else>Your maximum token allocation for this round has been reached. Thank you for participating.</p>
      <a href="#" class="tokens-form__cancel" @click="$emit('cancelForm', $event)">Close</a>
    </template>
  </form>
</template>

<style lang="scss">
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.tokens-form {
  border: solid 1px $color-secondary;
  border-radius: 5px;
  overflow: hidden;
  padding: 1rem;
  margin: 0 0 2rem;

  &__formgroup {
    margin-bottom: 2rem;
  }

  &__inputgroup {
    align-items: center;
    display: flex;
  }

  &__loader {
    display: block;
    margin-bottom: 1rem;
  }

  &__label {
    display: block;
    font-size: 1.3125rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__selectbox {
    position: relative;
  }

  &__select {
    appearance: none;
    cursor: pointer;
    display: block;
    background-color: #f7f9fc;
    border-radius: 5px;
    border: solid 1px #ddd;
    font-size: inherit;
    margin-bottom: 1rem;
    padding: 1rem;
    transition: border 0.25s;
    width: 100%;
  }

  &__arrow {
    color: $color-primary;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    height: 1.75rem;
    width: 1.75rem;
  }

  &__input {
    display: block;
    background-color: #f7f9fc;
    border-radius: 5px;
    border: solid 1px #ddd;
    padding: 1rem;
    transition: border 0.25s;
    width: 100%;

    &:focus {
      background-color: #fff;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__inputlabel {
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: 1rem;
  }

  &__description {
    margin-bottom: 0.5rem;
  }

  &__submit {
    display: block;
    width: 100%;
  }

  &__cancel {
    display: inline-block;
    margin-top: 0.5rem;
    float: right;
  }

  @media screen and (min-width: 768px) {
    &__group {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &__submit {
      order: 2;
      width: auto;
    }

    &__cancel {
      float: none;
      order: 1;
    }
  }
}
</style>
