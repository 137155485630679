<script>
/* eslint-disable camelcase */
import { toRefs, watch, nextTick, computed } from 'vue';
import { nanoid } from 'nanoid';
import { appConfig } from './config';
import AccordionBlock from './components/AccordionBlock.vue';
import Button from './components/Button.vue';
import Block from './components/Block.vue';
import CopyText from './components/CopyText';
import Loader from './components/Loader.vue';
import QrCode from './components/QrCode';
import Typography from './components/Typography';
import TokensContainer from './components/Tokensale/TokensContainer';
import BlockpassIcon from './components/icons/BlockpassIcon';
import CardanoTokenIcon from './components/icons/CardanoTokenIcon';
import EmpowaTokenIcon from './components/icons/EmpowaTokenIcon';
import ReferralIcon from './components/icons/ReferralIcon';
import TermsIcon from './components/icons/TermsIcon';
import { useBlockpass } from './composables/useBlockpass';
import { useCookie } from './composables/useCookie';
import { useFetch } from './composables/useFetch';
import { useState } from './composables/useState';
import { useValidation } from './composables/useValidation';
import { useUserWindow } from './composables/useUserWindow';
import heroIcon from './assets/images/hero-icon.png';
import logo from './assets/icons/logo-text.svg';
import InstructionsModal from './components/Tokensale/TokensPurchase/InstructionsModal.vue';
import WalletModal from './components/Tokensale/TokensPurchase/WalletModal.vue';
import SurveyModal from './components/Tokensale/TokensPurchase/SurveyModal.vue';
import InfoButton from './components/InfoButton.vue';
import CheckIcon from './components/icons/CheckIcon.vue';

export default {
  components: {
    AccordionBlock,
    Block,
    Button,
    CopyText,
    Loader,
    QrCode,
    TokensContainer,
    Typography,
    InstructionsModal,
    SurveyModal,
    WalletModal,

    // icons
    BlockpassIcon,
    CardanoTokenIcon,
    EmpowaTokenIcon,
    ReferralIcon,
    TermsIcon,
    InfoButton,
    CheckIcon,
  },
  setup() {
    const options = { ...appConfig };
    const { state, setState } = useState();
    const { fetchData } = useFetch();
    const { getCookie, setCookie, removeCookie } = useCookie();
    const { validateEmail } = useValidation();
    const { isUserWindowActive } = useUserWindow();
    const { loadBlockpassWidget, getBlockpassCandidate } = useBlockpass({
      clientId: options.blockpass.clientId,
      env: options.blockpass.env,
      apiUrl: options.api.blockpass,
      token: options.tokens.blockPass,
      mainColor: 'f9792f',
    });

    // Get current session from session / params
    const urlParams = new URLSearchParams(window.location.search);
    const newEmail = urlParams.get('email') || null;
    const newHash = urlParams.get('hash') || null;
    const newReferral = urlParams.get('referral') || null;
    const hasAccess = (urlParams.get('login') && urlParams.get('login') === 'Empowa2023') || null;
    const purchaserIdFromStorage = getCookie('purchaser_id') || null;
    const sessionIdFromStorage = getCookie('session_id') || null;

    // Assign interval to this var
    let stateInterval;
    let blockpassInterval;
    let fcnftInterval;

    setState({
      // Purchaser API
      claims: [],
      purchaser_id: null,
      email: null,
      session_id: null,
      empowa_wallet_addr: null,
      user_stake_key: null,
      terms_ack: null,
      referred_by: null,
      fc_member: null,
      fcnft: null,
      tokens: [],
      tokens_active: false,
      referrals_count: 0,
      referrals_purchased_count: 0,
      round4_survey: null,
      is_admin: false,

      // App specific
      isAuthenticated: false,
      isVerificationEmailSent: false,
      fcnftOwner: null,
      fcnftMetadata: null,
      tokensPurchasedTotal: null,
      tokensClaimedTotal: null,

      // Status
      appLoading: false,
      appError: null,

      emailLinkLoading: false,
      emailLinkError: null,

      emailCheckLoading: false,
      emailCheckError: null,

      fcnftOwnerLoading: false,
      fcnftOwnerError: null,
      fcnftOwnerCompleted: false,

      walletLinkLoading: false,
      walletLinkError: null,
      walletLinkCompleted: false,

      termsConditionLoading: false,
      termsConditionError: null,
      termsConditionCompleted: false,

      blockpassLoading: false,
      blockpassError: null,
      blockpassStatus: null,
      blockpassCompleted: false,
    });

    const goToLogin = () => {
      setState({ isAuthenticated: false });
    };

    const goToDashboard = () => {
      setState({ isAuthenticated: true });
    };

    const clearSession = () => {
      removeCookie('purchaser_id');
      removeCookie('session_id');
    };

    const clearParms = () => {
      window.history.pushState({}, '', document.location.href.split('?')[0]);
    };

    // Authentication step: Get Initial State from API
    const getInitialState = async ({ purchaserId = null, sessionId = null }) => {
      let retryCount = 0;
      let data = null;
      let error = null;

      setState({
        appLoading: true,
        appError: null,
      });

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { result: apiResult, error: apiError } = fetchData({
          url: `${options.api.purchaser}/${purchaserId}?fetchchildren=true`,
          method: 'GET',
          headers: {
            apikey: options.tokens.restDb,
            Authorization: sessionId,
          },
        });

        // eslint-disable-next-line no-await-in-loop
        const apiData = await apiResult.value;

        if (apiData?.session_id) {
          data = apiData;

          break;
        }

        if (apiError.value || retryCount === 5) {
          error = apiError.value;

          break;
        }

        retryCount += 1;
      }

      setState({
        appLoading: false,
      });

      if (data && data?.session_id === sessionId) {
        const expirationDays = 7;

        setState({ ...data, purchaser_id: data._id });
        setCookie('purchaser_id', data._id, expirationDays);
        setCookie('session_id', data.session_id, expirationDays);

        // Allow access to dashboard
        goToDashboard();
      } else if (error === 'Forbidden!') {
        setState({
          appError: 'Your session has expired. Please register for a new email link.',
        });

        removeCookie('purchaser_id');
        removeCookie('session_id');

        // Make user log in again
        goToLogin();
      } else {
        setState({
          appError: `We can't reach the server at the moment. Please try again later.`,
        });
      }
    };

    // Get State from API
    const getState = async ({ purchaserId = null, sessionId = null }) => {
      const { result } = fetchData({
        url: `${options.api.purchaser}/${purchaserId}?fetchchildren=true `,
        method: 'GET',
        headers: {
          apikey: options.tokens.restDb,
          Authorization: sessionId,
        },
      });

      const data = await result.value;

      if (data?.session_id) {
        if (data.session_id === sessionId) {
          setState({ ...data });
        } else {
          setState({
            appError: 'Your session has expired. Please register for a new email link.',
          });

          removeCookie('purchaser_id');
          removeCookie('session_id');

          // Make user log in again
          goToLogin();
        }
      }
    };

    // Authentication step: handle email verification
    const verifyEmailAddress = async (email, hash) => {
      const sessionId = nanoid(); // generate new sessionId
      const formData = new FormData();
      formData.append('email', email);
      formData.append('hash', hash);
      formData.append('session_id', sessionId);

      const { result, error, loading } = fetchData({
        url: options.api.emailCheck,
        method: 'POST',
        body: formData,
      });

      setState({
        appLoading: loading,
        appError: error,
      });

      const data = await result.value;

      if (data?.purchaser_id) {
        await getInitialState({ purchaserId: data.purchaser_id, sessionId });
        clearParms();
      } else {
        setState({
          appError: 'Your email link has expired, please register for a new link.',
        });

        goToLogin();
      }
    };

    // Authentication step: login with email
    const handleLoginWithEmail = async event => {
      event.preventDefault();

      const form = event.target;
      const formData = new FormData(form);
      const email = form.elements.email.value.toLowerCase();
      const isValid = validateEmail(email);

      setState({
        appError: null,
      });

      if (!isValid) {
        setState({
          appError: null,
          emailLinkError: 'Please enter a valid email address.',
        });

        return;
      }

      // Prepare formData
      formData.append('email', email);
      formData.append('dashboard_host', options.app.url);

      const { result, error, loading } = fetchData({
        url: options.api.emailLink,
        method: 'POST',
        body: formData,
      });

      setState({
        emailLinkLoading: loading,
        emailLinkError: error,
      });

      const data = await result.value;

      if (data === 'Verification Email Sent!') {
        setState({ isVerificationEmailSent: true });
      } else {
        setState({
          error: {
            emailCheck: error.value?.message,
          },
        });
      }
    };

    const handleFcnftOwner = async event => {
      const radio = event.target;
      const fcnftOwner = radio.value;
      const { purchaser_id: purchaserId, session_id: sessionId } = state;

      const { result, error, loading } = fetchData({
        url: `${options.api.purchaser}/${purchaserId}`,
        method: 'PATCH',
        body: JSON.stringify({
          fc_member: fcnftOwner === 'no' ? 'no' : null,
        }),
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          'Content-Type': 'application/json',
        },
      });

      setState({
        fcnftOwnerLoading: loading,
        fcnftOwnerLinkError: error,
      });

      const data = await result.value;

      if (data) {
        setState({ fc_member: data?.fc_member, fcnftOwner, fcOwnerCompleted: true });
      }
    };

    const handleWalletLinking = async event => {
      const radio = event.target;
      const fcMember = radio.value;
      const { purchaser_id: purchaserId, session_id: sessionId } = state;

      const { result, error, loading } = fetchData({
        url: `${options.api.purchaser}/${purchaserId}`,
        method: 'PATCH',
        body: JSON.stringify({
          fc_member: fcMember,
        }),
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          'Content-Type': 'application/json',
        },
      });

      setState({
        walletLinkLoading: loading,
        walletLinkError: error,
      });

      const data = await result.value;

      if (data) {
        setState({ fc_member: data?.fc_member, walletLinkCompleted: true });
      }
    };

    // Handle Terms and Condition consent
    const handleTermsAndCondition = async event => {
      event.preventDefault();

      const form = event.target;
      const consent = form.elements.terms_ack;
      const hasConsented = consent.checked;
      const { purchaser_id: purchaserId, session_id: sessionId } = state;

      // Validate first
      if (!hasConsented) {
        setState({
          termsConditionError: 'Please consent first.',
        });

        return;
      }

      const { result, error, loading } = fetchData({
        url: `${options.api.purchaser}/${purchaserId}`,
        method: 'PATCH',
        body: JSON.stringify({
          terms_ack: true,
        }),
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          'Content-Type': 'application/json',
        },
      });

      setState({
        termsConditionLoading: loading,
        termsConditionError: error,
      });

      const data = await result.value;

      if (data) {
        setState({
          terms_ack: data?.terms_ack,
        });
      }
    };

    const handleExitDashboard = event => {
      event.preventDefault();

      setState({
        appError: 'Your dashboard session has ended',
      });

      clearSession();
      goToLogin();
    };

    const getFcnftLevel = async userStakeKey => {
      const { purchaser_id: purchaserId, session_id: sessionId } = state;
      let assets = [];
      let assetsPage = 1;

      // Keep looping through all pages until no items are in response...
      /* eslint-disable no-constant-condition */
      while (true) {
        const { result } = fetchData({
          url: `${options.api.assets(userStakeKey)}?page=${assetsPage}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            project_id: options.tokens.blockFrost,
          },
        });

        /* eslint-disable no-await-in-loop */
        const data = await result.value;

        // Add to array + increase page
        if (data) {
          assets = [...assets, ...data];
          assetsPage += 1;
        } else {
          break;
        }

        if (data?.length < 100) {
          break;
        }
      }

      const fcnft = assets.length > 0 ? assets.find(nft => nft.unit.includes(options.fcNft.unit)).unit : null;

      const { result: resultPatch } = fetchData({
        url: `${options.api.purchaser}/${purchaserId}`,
        method: 'PATCH',
        body: JSON.stringify({
          fcnft,
        }),
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          'Content-Type': 'application/json',
        },
      });

      const patchResponse = await resultPatch.value;

      // Get fcnft metadata
      if (fcnft && patchResponse) {
        if (patchResponse?.fcnft === fcnft) {
          const { result: resultAsset } = fetchData({
            url: options.api.singleAsset(fcnft),
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              project_id: options.tokens.blockFrost,
            },
          });

          const fcnftData = await resultAsset.value;

          if (fcnftData) {
            const { onchain_metadata: fcnftMetadata } = fcnftData;

            setState({
              fcnftMetadata,
            });
          }
        }
      }
    };

    const gotoWallet = () => {
      window.open(`${options.walletPool.url}/${state.user_stake_key}`, '_blank');
    };

    const completeSteps = ({ userStakeKey, termsAck, fcMember, blockpassStatus }) => {
      const hasWalletLinkedPreference = !!userStakeKey || fcMember === 'no' || fcMember === 'yes-and-no';
      const hasAgreedToTermsAndCondition = !!termsAck;
      const hasRegisteredToBlockpass = blockpassStatus === 'approved';

      setState({ walletLinkCompleted: hasWalletLinkedPreference });
      setState({ termsConditionCompleted: hasAgreedToTermsAndCondition });
      setState({ blockpassCompleted: hasRegisteredToBlockpass });
    };

    const stepsCompleted = computed(() => {
      const { walletLinkCompleted, termsConditionCompleted, blockpassCompleted } = state;

      return !!(walletLinkCompleted && termsConditionCompleted && blockpassCompleted);
    });

    const isWalletLinked = computed(() => {
      const { user_stake_key } = state;
      return !!(user_stake_key && user_stake_key !== '');
    });

    const hasFcnft = computed(() => {
      const { fcnft } = state;
      return !!(fcnft && fcnft !== '');
    });

    const updateFrequently = (purchaserId, sessionId, userStakeKey, isAdmin) => {
      stateInterval = setInterval(() => {
        isUserWindowActive.value && getState({ purchaserId, sessionId });
      }, 60000);

      blockpassInterval = setInterval(() => {
        getBlockpassCandidate(purchaserId);
      }, 20000);

      if (!isAdmin) {
        fcnftInterval = setInterval(() => {
          getFcnftLevel(userStakeKey);
        }, 900000);
      }
    };

    // Authenticate user for access
    const authenticate = () => {
      const hasStoredSession = !!(purchaserIdFromStorage && sessionIdFromStorage);
      const hasNewSessionRequest = !!(newEmail && newHash);
      const hasNoSession = !!(!hasStoredSession && !hasNewSessionRequest);

      if (hasStoredSession) {
        getInitialState({ purchaserId: purchaserIdFromStorage, sessionId: sessionIdFromStorage });
      } else if (hasNewSessionRequest) {
        verifyEmailAddress(newEmail, newHash);
      } else if (hasNoSession) {
        goToLogin();
      }
    };

    // Show wallet modal
    const showWalletInstructionsModal = () => {
      setState({ showWalletModal: true });
    };

    // Check if any of the initial state changes
    watch(
      [
        () => state.isAuthenticated,
        () => state.user_stake_key,
        () => state.blockpassStatus,
        () => state.fcnft,
        () => state.fc_member,
        () => state.terms_ack,
      ],
      (
        [newValueIsAuthenticated, newValueUserStakeKey, newValueBlockpassStatus, newValueFcnft],
        [oldValueIsAuthenticated, oldValueUserStakeKey, oldValueBlockpassStatus, oldValueFcnft],
      ) => {
        const hasAuthenticationChanged = newValueIsAuthenticated !== oldValueIsAuthenticated;
        const hasUserStakeKeyChanged = newValueUserStakeKey !== oldValueUserStakeKey;
        const hasBlockpassStatusChanged = newValueBlockpassStatus !== oldValueBlockpassStatus;
        const hasFcnftStatusChanged = newValueFcnft !== oldValueFcnft;

        const {
          isAuthenticated,
          blockpassStatus,
          email,
          fc_member: fcMember,
          user_stake_key: userStakeKey,
          terms_ack: termsAck,
          purchaser_id: purchaserId,
          session_id: sessionId,
          is_admin: isAdmin,
          fcnft,
        } = state;

        if (hasAuthenticationChanged) {
          if (isAuthenticated) {
            nextTick(() => {
              loadBlockpassWidget(purchaserId, email);
              updateFrequently(purchaserId, sessionId, userStakeKey, isAdmin);
            });
          } else {
            clearInterval(stateInterval);
            clearInterval(blockpassInterval);
            clearInterval(fcnftInterval);
          }
        }

        if (hasUserStakeKeyChanged && userStakeKey && !isAdmin) {
          getFcnftLevel(userStakeKey);
        } else if (isAdmin && !fcnft) {
          getFcnftLevel(userStakeKey);
        }

        if (hasBlockpassStatusChanged && blockpassStatus === 'approved') {
          clearInterval(blockpassInterval);
        }

        if (hasFcnftStatusChanged && fcnft && !isAdmin) {
          clearInterval(fcnftInterval);
        }

        completeSteps({
          userStakeKey,
          termsAck,
          fcMember,
          blockpassStatus,
        });
      },
    );

    const init = () => {
      authenticate();
    };

    // Init dashboard
    init();

    return {
      ...toRefs(state),
      options,
      newReferral,
      stepsCompleted,
      isWalletLinked,
      hasFcnft,
      hasAccess,

      // Handlers
      handleFcnftOwner,
      handleWalletLinking,
      handleLoginWithEmail,
      handleTermsAndCondition,
      handleExitDashboard,
      showWalletInstructionsModal,
      gotoWallet,

      // Assets
      heroIcon,
      logo,
    };
  },
};
</script>

<template>
  <section class="app">
    <Loader v-if="appLoading" class="app__loader">Authenticating...</Loader>
    <div v-if="!appLoading" class="app__container">
      <div class="app__top" v-if="appError">
        <div class="notification">
          <div class="notification__container">
            <span v-if="appError">{{ appError }}</span>
          </div>
        </div>
      </div>
      <header class="app__header" style="position: relative">
        <div class="header">
          <div class="header__container">
            <a class="header__logo" href="#">
              <img :src="logo" alt="Empowa logo" />
            </a>
            <nav class="nav">
              <ul class="nav__list">
                <li class="nav__item"><a class="nav__link" href="https://empowa.io">Home</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <main class="app__main">
        <section class="section section--hero hero">
          <div class="hero__content">
            <header class="hero__header">
              <img class="hero__icon" :src="heroIcon" alt="EMP token icon" />
              <Typography tag="h1" class="hero__heading">EMP Token Sale</Typography>
              <Typography tag="h2" class="hero__subheading">
                <span>Dashboard</span>
              </Typography>
            </header>
          </div>
        </section>

        <!-- REGISTRATION -->
        <section v-if="!isAuthenticated" class="section section--small section--overlap">
          <div class="section__container">
            <ul class="block-list">
              <li class="block-list__item">
                <Block variant="orange" v-if="hasAccess">
                  <template v-if="!options.app.isMaintenanceMode">
                    <form class="form" @submit="handleLoginWithEmail" v-if="!isVerificationEmailSent">
                      <Typography tag="h1" line-height="special" weight="medium" size="l">
                        Enter your email to begin a Token Sale Dashboard session
                      </Typography>
                      <input
                        class="form__input"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="your@email.com"
                        required
                      />
                      <input
                        v-if="newReferral"
                        name="referral"
                        id="referral"
                        class="form__input"
                        type="hidden"
                        :value="newReferral"
                      />
                      <div>
                        <Loader variant="quaternary" v-if="emailLinkLoading" class="form__loader">
                          Sending email...
                        </Loader>
                        <p v-if="emailLinkError">
                          {{ emailLinkError }}
                        </p>
                      </div>
                      <Button variant="ghost-quaternary" class="form__button">Send me a session link</Button>
                    </form>
                    <div v-else>
                      <h2>Email Sent!</h2>
                      <p>Please check your inbox to verify your email address.</p>
                      <p>Didn't receive an email? Make sure to also check your spam folder.</p>
                      <p class="mb-0">Here are some useful links:</p>
                      <ul>
                        <li><a href="https://t.me/empowa_chat" target="_blank">Empowa Telegram</a></li>
                        <li><a href="https://empowa.io/faq/" target="_blank">Token Sale FAQ</a></li>
                      </ul>
                    </div>
                  </template>
                  <template v-else>
                    <Typography tag="h1" line-height="special" weight="medium" size="l" style="text-align: center">
                      Temporary maintenance underway
                    </Typography>
                    <p style="text-align: center">Please bare with us while we optimize the application.</p>
                  </template>
                </Block>
              </li>
              <li class="block-list__item" v-if="!hasAccess">
                <Block class="block">
                  <Typography tag="h1" weight="bold" size="xl"
                    >The Empowa Token Sale Dashboard has been decommissioned.</Typography
                  >
                  <p>
                    <strong>Claims History:</strong> All EMP token claims made through the Token Sale Dashboard have
                    been migrated to the
                    <a href="https://empowa.app" target="_blank">Empowa Explorer.</a>
                  </p>
                  <p>
                    <strong>Purchase History:</strong> To check the EMP purchases you made during the token sale, please
                    use the query tool at
                    <a href="https://purchases.tokensale.empowa.io">https://purchases.tokensale.empowa.io</a>.
                  </p>
                  <p>
                    If you need any assistance accessing your EMP purchase or claims history, please contact our support
                    team using team using team using the
                    <a href="https://empowa-tech.atlassian.net/servicedesk/customer/portal/1" target="_blank">
                      Support Portal </a
                    >, via <a href="https://t.me/emp_tokensalesupport" target="_blank">Telegram</a> or on
                    <a href="https://discord.gg/53hZwSTPMq" target="_blank">Discord</a>.
                  </p>
                </Block>
              </li>
            </ul>
          </div>
        </section>

        <!-- LOGGED IN -->
        <section v-else class="section section--small section--overlap">
          <SurveyModal />
          <InstructionsModal />
          <WalletModal />
          <div class="section__container">
            <!-- 0. Information user -->
            <Block class="mb-32 user-status">
              <div class="user-status__session-status">
                <div>
                  <div class="user-status__session-status mb-8">
                    <div>
                      <p class="mb-0">Current session linked to:</p>
                      <Typography size="m" weight="medium">{{ email }}</Typography>
                    </div>
                  </div>
                  <div>
                    <div style="display: flex; align-items: center">
                      <div
                        class="user-status__wallet-status"
                        :class="`${isWalletLinked ? 'wallet-linked' : 'wallet-not-linked'}`"
                      >
                        <CheckIcon v-if="isWalletLinked" class="user-status__icon mb-2 mr-4" />
                        <Typography color="quaternary" size="s" weight="medium">
                          Wallet {{ isWalletLinked ? 'linked' : 'not Linked' }}</Typography
                        >
                      </div>
                      <InfoButton
                        class="ml-8 mt-4"
                        tooltipText="Click for more information"
                        @click="showWalletInstructionsModal()"
                      />
                    </div>
                    <Button class="mt-8 user-status__goto-button" v-if="isWalletLinked" @click="gotoWallet()">
                      View Wallet
                    </Button>
                  </div>
                </div>
                <Button class="user-status__button mt-8" variant="secondary" @click="handleExitDashboard">
                  Exit Dashboard
                </Button>
              </div>
            </Block>

            <div
              class="block-groups mt-48"
              :class="{
                'is-completed': stepsCompleted,
              }"
            >
              <div class="block-groups__item" :class="{ stepsCompleted }">
                <Typography class="mb-32" color="primary" tag="h1" size="xl" weight="normal" style="text-align: center">
                  <span v-if="stepsCompleted"> Token Sale Registration completed! </span>
                  <span v-else>Complete the Token Sale Registration</span>
                </Typography>

                <!-- STEPS -->
                <ul class="block-list">
                  <!-- 1. Your Cardano Wallet -->
                  <li class="block-list__item" :class="{ 'is-completed': walletLinkCompleted }">
                    <AccordionBlock variant="primary" :is-completed="walletLinkCompleted">
                      <template v-slot:icon>
                        <CardanoTokenIcon />
                      </template>
                      <template v-slot:title>Founding Community</template>
                      <template v-slot:description>
                        Link your Cardano wallet with a FC NFT to receive priority access
                      </template>
                      <template v-slot:content>
                        <!-- No wallet linked: instructions -->
                        <template v-if="!hasFcnft">
                          <div style="margin-bottom: 2rem">
                            <p>Do you own an Empowa Founding Community NFT?</p>
                            <form class="form">
                              <div class="form__radiogroup">
                                <label class="mr-20" for="fc_owner_yes">
                                  <input
                                    class="form__radio"
                                    type="radio"
                                    id="fc_owner_yes"
                                    name="fc_owner"
                                    value="yes"
                                    @input="handleFcnftOwner"
                                    :checked="fc_member === 'yes' || fc_member === 'yes-and-no'"
                                  />
                                  Yes
                                </label>
                                <label for="fc_owner_no">
                                  <input
                                    class="form__radio"
                                    type="radio"
                                    id="fc_owner_no"
                                    name="fc_owner"
                                    value="no"
                                    @input="handleFcnftOwner"
                                    :checked="fc_member === 'no'"
                                  />
                                  No
                                </label>
                              </div>
                            </form>

                            <Loader class="mt-16 mb-16" v-if="fcnftOwnerLoading">Submitting your preference...</Loader>
                            <p class="mt-16" style="color: red" v-if="fcnftOwnerError">
                              Something went wrong... Please try again later.
                            </p>
                          </div>

                          <div v-if="fcnftOwner === 'yes' || fc_member === 'yes' || fc_member === 'yes-and-no'">
                            <p>
                              As a Founding Community NFT owner you have the option to receive priority access to the
                              Empowa Token Sale, by linking a Cardano wallet containing your FC NFT to the token sale
                              dashboard.
                            </p>
                            <p>Would you like to use your Founding Community NFT to receive priority access?</p>

                            <form class="form">
                              <div class="form__radiogroup">
                                <label class="mr-20" for="wallet_link_yes">
                                  <input
                                    class="form__radio"
                                    type="radio"
                                    id="wallet_link_yes"
                                    name="wallet_link"
                                    value="yes"
                                    @input="handleWalletLinking"
                                    :checked="fc_member === 'yes'"
                                  />
                                  Yes
                                </label>
                                <label for="wallet_link_no">
                                  <input
                                    class="form__radio"
                                    type="radio"
                                    id="wallet_link_no"
                                    name="wallet_link"
                                    value="yes-and-no"
                                    @input="handleWalletLinking"
                                    :checked="fc_member === 'yes-and-no'"
                                  />
                                  No
                                </label>
                              </div>
                            </form>

                            <Loader class="mt-16 mb-16" v-if="walletLinkLoading">Submitting your preference...</Loader>
                            <p class="mt-16" style="color: red" v-if="walletLinkError">
                              Something went wrong... Please try again later.
                            </p>
                          </div>

                          <!-- Show wallet instructions after choice been made -->
                          <div class="mt-32" v-if="fc_member === 'yes' && isWalletLinked">
                            <p>
                              <strong>
                                Your wallet is already linked. Please ensure your FCNFT is in the same wallet to receive
                                priority access.
                              </strong>
                            </p>
                          </div>
                          <div class="mt-32" v-else-if="fc_member === 'yes' && !isWalletLinked">
                            <!-- Empowa Wallet Address not available yet -->
                            <div v-if="!empowa_wallet_addr && empowa_wallet_addr === ''">
                              <p><strong>Retrieving Address</strong></p>
                              <p>
                                If an address is not displayed within 5 minutes, please contact support via
                                <a href="mailto:https://empowa.io/token-sale" target="_blank">
                                  https://empowa.io/token-sale</a
                                >.
                              </p>
                            </div>

                            <!-- Empowa Wallet available -->
                            <div v-else>
                              <p>
                                To link your wallet, please send 1 ADA to the address below. <br />
                                For security reasons, once your wallet is linked it cannot be changed
                              </p>
                              <QrCode :url="empowa_wallet_addr" label="Payment Address" />
                            </div>
                          </div>
                          <div class="mt-32" v-else-if="fc_member === 'no' || fc_member === 'yes-and-no'">
                            <p><strong>Thank you.</strong></p>

                            <p>You can change this selection at a later date.</p>
                            <p>
                              NOTE: FC NFTs cannot be shared and will not work if used with another person’s Token Sale
                              registration.
                            </p>
                          </div>
                        </template>

                        <!-- Wallet is linked -->
                        <template v-else>
                          <p>
                            Your wallet has been successfully linked. For security reasons, your linked wallet cannot be
                            changed.
                          </p>

                          <div v-if="fcnftMetadata">
                            <p>Founding Community Priority Access: <strong style="color: #f9792f">Granted</strong></p>
                            <p>
                              <strong>{{ fcnftMetadata.name }}</strong> detected in your linked wallet
                            </p>
                          </div>
                          <div v-else>
                            <p>
                              Founding Community Priority Access: <strong style="color: #fb2b2d">NOT Permitted</strong>
                              <br />
                            </p>
                            <p>
                              No Founding Community NFTs were detected in your linked wallet. You will need to transfer
                              a FC NFT into your linked wallet to receive priority access during the token sale.
                            </p>
                          </div>
                        </template>
                      </template>
                    </AccordionBlock>
                  </li>

                  <!-- 2. Terms & conditions -->
                  <li class="block-list__item" :class="{ 'is-completed': termsConditionCompleted }">
                    <AccordionBlock :is-completed="termsConditionCompleted">
                      <template v-slot:icon>
                        <TermsIcon />
                      </template>
                      <template v-slot:title>Terms & Conditions</template>
                      <template v-slot:description>Read and accept our terms and conditions.</template>
                      <template v-if="!terms_ack && terms_ack !== ''" v-slot:content>
                        <p>
                          To participate in the Empowa Token Sale, you must read and accept the Terms and Conditions
                          available at
                          <a href="https://empowa.io/token-sale-terms" target="_blank">
                            https://empowa.io/token-sale-terms</a
                          >.
                        </p>
                        <form class="form" @submit="handleTermsAndCondition">
                          <div class="mb-20">
                            <input
                              type="checkbox"
                              name="terms_ack"
                              id="terms_ack"
                              class="form__checkbox"
                              style="margin-right: 5px"
                            />
                            <label for="terms_ack">I agree with the terms and conditions.</label>
                          </div>
                          <Loader v-if="termsConditionLoading">Submitting...</Loader>
                          <p v-if="termsConditionError">{{ termsConditionError }}</p>
                          <div><Button>Submit</Button></div>
                        </form>
                      </template>
                      <template v-else v-slot:content>
                        <p>
                          <strong>Thank you. You have agreed to the Empowa Token Sale Terms and Conditions.</strong>
                        </p>
                        <p>
                          The Terms and Conditions are available at
                          <a href="https://empowa.io/token-sale-terms" target="_blank">
                            https://empowa.io/token-sale-terms</a
                          >.
                        </p>
                      </template>
                    </AccordionBlock>
                  </li>

                  <!-- 3. KYC Information -->
                  <li class="block-list__item" :class="{ 'is-completed': blockpassCompleted }">
                    <AccordionBlock :is-completed="blockpassCompleted">
                      <template v-slot:icon>
                        <BlockpassIcon />
                      </template>
                      <template v-slot:title>Identity Verification (KYC)</template>
                      <template v-slot:description>
                        KYC through our service provider Blockpass is required to participate in the Token Sale
                      </template>
                      <template v-slot:content>
                        <Loader v-if="blockpassLoading">Getting status...</Loader>

                        <!-- KYC: Not started - null / false -->
                        <template v-if="!blockpassStatus">
                          <p>Status: <strong>Not started</strong>.</p>
                        </template>

                        <!-- KYC: Pending - incomplete | inreview | waiting -->
                        <template
                          v-if="
                            blockpassStatus === 'incomplete' ||
                            blockpassStatus === 'waiting' ||
                            blockpassStatus === 'inreview'
                          "
                        >
                          <p>Status: <strong>Pending</strong>.</p>
                          <p>
                            Please allow up to 2 days for review. <br />
                            After that if there is no change, please contact our support team using the links available
                            at
                            <a href="https://empowa.io/token-sale" target="_blank">https://empowa.io/token-sale</a>.
                          </p>
                        </template>

                        <!-- KYC: Not Successful - resubmit -->
                        <template v-if="blockpassStatus === 'resubmit' || blockpassStatus === 'rejected'">
                          <p>Status: <strong>Not Successful</strong>.</p>
                          <p>
                            There was a problem with your identity verification. Please check the feedback supplied by
                            Blockpass. <br />
                            If you believe this is incorrect please contact our support team using the links available
                            at
                            <a href="https://empowa.io/token-sale" target="_blank">https://empowa.io/token-sale</a>.
                          </p>
                        </template>

                        <!-- KYC: Successful - approved -->
                        <template v-if="blockpassStatus === 'approved'">
                          <p>Status: <strong>Successful</strong>.</p>
                          <p>Thank you for completing your identity verification.</p>
                        </template>

                        <!-- KYC Button -->
                        <Button v-show="!blockpassStatus" variant="secondary" id="blockpass-kyc-connect" class="mt-15">
                          Launch Blockpass
                        </Button>
                      </template>
                    </AccordionBlock>
                  </li>
                </ul>
              </div>

              <div class="block-groups__item">
                <Typography
                  class="mb-32"
                  color="secondary"
                  tag="h1"
                  size="xl"
                  weight="normal"
                  style="text-align: center"
                >
                  <span>Token Sale Overview</span>
                </Typography>

                <!-- Data -->
                <ul class="block-list">
                  <!-- 4. Referral Code -->
                  <li class="block-list__item">
                    <AccordionBlock>
                      <template v-slot:icon>
                        <ReferralIcon />
                      </template>
                      <template v-slot:title>Referrals</template>
                      <template v-slot:description>Refer others to receive priority access</template>
                      <template v-slot:content>
                        <p>
                          Telling your friends and family about the Empowa Token Sale will allow you to earn priority
                          access in Round 3 (and Round 4 if it is required).
                        </p>
                        <p>
                          If people register in the Token Sale Dashboard for the first time using your referral link and
                          they purchase tokens in either Round 1 or 2, you will receive priority access in Rounds 3 and
                          4 proportional to the number of participants you refer.
                        </p>
                        <p>To have people register with your referral code, please send them this link</p>
                        <CopyText class="mb-24" :text="`https://tokensale.empowa.io/?referral=${purchaser_id}`" />
                        <p>
                          <strong>Referrals Registered:</strong> {{ referrals_count }} <br />
                          <span v-if="referrals_count > 0">
                            <strong>Referrals Purchased:</strong> {{ referrals_purchased_count }}
                          </span>
                        </p>
                      </template>
                    </AccordionBlock>
                  </li>

                  <!-- 5. My Tokens -->
                  <li class="block-list__item">
                    <AccordionBlock>
                      <template v-slot:icon>
                        <EmpowaTokenIcon />
                      </template>
                      <template v-slot:title>My Tokens</template>
                      <template v-slot:content>
                        <template v-if="is_admin || stepsCompleted">
                          <TokensContainer
                            :tokens="tokens"
                            :rounds="options.tokensale.rounds"
                            :statusPerRound="options.tokensale.statusPerRound"
                            :wallet-address="empowa_wallet_addr"
                            :has-fcnft="hasFcnft"
                            :is-tokens-active="tokens_active"
                            :purchaserId="purchaser_id"
                            :max-tokens-per-person="options.tokensale.maxTokenPerPerson"
                            :max-tokens-per-person-fcnft="options.tokensale.maxTokenPerPersonFcnft"
                          />
                        </template>
                        <template v-else>
                          <p>Please complete the required steps above first to activate this section.</p>
                        </template>
                      </template>
                    </AccordionBlock>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer class="app__footer"></footer>
    </div>
  </section>
</template>

<style lang="scss">
@import 'assets/scss/variables';
@import 'assets/scss/mixins';
@import 'assets/scss/spacing-utils';

.app {
  background-color: #fff;

  &__loader {
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  &__header {
    position: relative;
  }
}

.header {
  color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  &__container {
    @include container;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__logo {
    display: block;
    margin: 0 auto 0.5rem auto;
    max-width: 200px;
  }
}

.nav {
  text-align: center;

  &__list {
    @include list-reset;
  }

  &__item {
    display: inline-block;
    margin-right: 2.5rem;

    &:last-child {
      margin: 0;
    }
  }

  &__link {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.notification {
  color: #fff;
  text-align: center;
  background-color: $color-secondary;

  &__container {
    @include container;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.message-banner {
  text-align: center;
  &__message {
    line-height: 2rem;
    margin: 0;
  }
}

.text-strike {
  text-decoration: line-through;
}

.hero {
  background: url('assets/images/hero-bg.jpg') center / cover no-repeat;
  display: flex;
  height: 320px;
  position: relative;

  &__content {
    color: #fff;
    margin: auto;
    position: relative;
    top: 3rem;
  }

  &__header {
    position: relative;
    text-align: center;

    // Line
    &::after {
      content: '';
      display: inline-block;
      background-color: $color-primary;
      height: 4px;
      max-width: 128px;
      margin-top: 1rem;
      width: 100%;
    }
  }

  &__heading {
    text-transform: uppercase;
    margin: 0 0 0.5rem;
  }

  &__subheading {
    margin: 0;
  }

  &__icon {
    margin: 0 auto 1.5rem auto;
    max-width: 64px;
  }
}

.section {
  margin: 0 auto;
  position: relative;

  &__container {
    @include container;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__content {
    padding: 2rem;
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__heading {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__subheading {
    color: $color-primary;
    font-size: 16px;
    font-weight: 400;
  }
}

.block-groups {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &:not(.is-completed) {
    .block-groups__item {
      &:first-child {
        padding: 0 0 2rem;
        margin: 0 0 4rem;
        border-bottom: solid 2px $color-grey--dark;
      }
    }
  }

  &.is-completed {
    .block-groups__item {
      &:last-child {
        border-bottom: solid 2px $color-grey--dark;
        padding: 0 0 2rem;
        margin: 0 0 4rem;
      }

      &:first-child {
        opacity: 0.5;
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }
}

.block-list {
  @include list-reset;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__item {
    margin-bottom: 1rem;

    &.is-completed {
      order: 99;
    }
  }
}

.form {
  display: block;

  &__input {
    display: block;
    background-color: #f7f9fc;
    border-radius: 5px;
    border: solid 1px #ddd;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: border 0.25s;
    width: 100%;

    &:focus {
      background-color: #fff;
    }
  }

  &__button {
    width: 100%;
  }
}

.user-status {
  &__wallet-status {
    padding: 2px 8px 0px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;

    &.wallet-linked {
      background-color: #5283a3;
    }

    &.wallet-not-linked {
      background-color: #ccc;
    }
  }

  &__button {
    align-self: flex-start;
  }

  &__session-status {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    color: $color-white;
    width: 24px;
  }
}

@media screen and (min-width: 768px) {
  .hero {
    height: 520px;

    &__content {
      top: -1rem;
    }

    &__icon {
      max-width: 100px;
    }
  }

  .nav {
    display: block;
  }

  .header {
    &__container {
      align-items: center;
      display: flex;
    }

    &__logo {
      display: inline-block;
      margin: 0 2rem 0 0;
    }
  }

  .section {
    &--small {
      max-width: 800px;
    }

    &--overlap {
      top: -8rem;

      .section__container {
        padding: 0;
      }
    }
  }

  .block-list {
    &__item {
      margin-bottom: 1.5rem;
    }
  }

  .block-groups {
    &__item {
      &:first-child {
        padding: 0 0 2rem;
        margin: 0 0 3rem;
      }
    }
  }
}
</style>
