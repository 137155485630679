<template>
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="30.5" stroke="currentColor" stroke-width="3" />
    <path
      d="M21.9191 31.942C21.875 32.7183 22.1406 33.4805 22.6578 34.0612C23.1749 34.642 23.9013 34.9938 24.6775 35.0396H24.8375C25.4093 35.039 25.9685 34.8709 26.4459 34.5562C26.9233 34.2415 27.2981 33.7939 27.524 33.2686C27.75 32.7433 27.8172 32.1634 27.7173 31.6003C27.6175 31.0373 27.355 30.5158 26.9622 30.1003C26.5694 29.6847 26.0635 29.3933 25.507 29.2619C24.9505 29.1305 24.3677 29.165 23.8305 29.361C23.2934 29.5571 22.8254 29.9061 22.4843 30.365C22.1432 30.824 21.9439 31.3727 21.9111 31.9436L21.9191 31.942Z"
      fill="currentColor"
    />
    <path
      d="M11.0004 31.1997C10.8137 31.1893 10.6281 31.2344 10.4671 31.3294C10.3061 31.4244 10.1768 31.565 10.0957 31.7334C10.0145 31.9018 9.98512 32.0905 10.0112 32.2757C10.0373 32.4608 10.1176 32.6341 10.2421 32.7735C10.3666 32.913 10.5297 33.0125 10.7107 33.0593C10.8917 33.1061 11.0825 33.0983 11.259 33.0367C11.4355 32.9751 11.5899 32.8626 11.7024 32.7133C11.815 32.5641 11.8809 32.3848 11.8916 32.1981C11.8987 32.074 11.8813 31.9497 11.8404 31.8324C11.7994 31.715 11.7358 31.6069 11.653 31.5141C11.5702 31.4214 11.47 31.3459 11.358 31.292C11.246 31.238 11.1245 31.2067 11.0004 31.1997Z"
      fill="currentColor"
    />
    <path
      d="M21.7334 14.9485C21.8997 14.8631 22.0369 14.7305 22.128 14.5672C22.219 14.404 22.2597 14.2175 22.245 14.0312C22.2302 13.8449 22.1607 13.6671 22.0451 13.5202C21.9296 13.3733 21.7731 13.2639 21.5955 13.2057C21.4179 13.1476 21.2271 13.1432 21.047 13.1933C20.8669 13.2434 20.7057 13.3456 20.5836 13.4871C20.4615 13.6286 20.384 13.803 20.3608 13.9885C20.3377 14.1739 20.3699 14.3621 20.4534 14.5293C20.5092 14.6415 20.5868 14.7415 20.6816 14.8234C20.7764 14.9054 20.8866 14.9677 21.0057 15.0067C21.1248 15.0457 21.2505 15.0606 21.3754 15.0506C21.5003 15.0407 21.622 15.0059 21.7334 14.9485Z"
      fill="currentColor"
    />
    <path
      d="M24.706 20.1294C24.9642 19.9992 25.178 19.7953 25.3203 19.5436C25.4627 19.2918 25.5272 19.0035 25.5057 18.7152C25.4843 18.4268 25.3778 18.1512 25.1997 17.9233C25.0217 17.6955 24.78 17.5255 24.5054 17.4349C24.2308 17.3443 23.9354 17.3372 23.6568 17.4145C23.3781 17.4917 23.1285 17.6498 22.9397 17.8689C22.7509 18.0879 22.6313 18.358 22.5959 18.645C22.5606 18.9321 22.6111 19.2231 22.7412 19.4814C22.8274 19.6532 22.9467 19.8062 23.0922 19.9318C23.2377 20.0574 23.4066 20.153 23.5891 20.2132C23.7716 20.2734 23.9642 20.297 24.1559 20.2826C24.3476 20.2682 24.5345 20.2162 24.706 20.1294Z"
      fill="currentColor"
    />
    <path
      d="M14.7055 23.6926C14.9046 23.8236 15.138 23.8927 15.3763 23.891C15.6145 23.8894 15.847 23.8171 16.0442 23.6833C16.2414 23.5496 16.3945 23.3603 16.4842 23.1396C16.5738 22.9188 16.596 22.6764 16.5479 22.443C16.4998 22.2096 16.3835 21.9958 16.2139 21.8284C16.0442 21.6611 15.8288 21.5479 15.5947 21.503C15.3607 21.4581 15.1186 21.4837 14.8991 21.5764C14.6796 21.6691 14.4925 21.8248 14.3615 22.0238C14.2745 22.156 14.2144 22.304 14.1846 22.4594C14.1548 22.6148 14.156 22.7745 14.1879 22.9295C14.2198 23.0844 14.282 23.2316 14.3708 23.3625C14.4596 23.4935 14.5734 23.6057 14.7055 23.6926Z"
      fill="currentColor"
    />
    <path
      d="M16.4862 30.6669C16.1975 30.6506 15.9104 30.7203 15.6613 30.8671C15.4122 31.0139 15.2122 31.2313 15.0866 31.4917C14.961 31.7522 14.9154 32.0441 14.9556 32.3304C14.9959 32.6168 15.1201 32.8848 15.3126 33.1006C15.5051 33.3163 15.7573 33.4702 16.0372 33.5427C16.3172 33.6152 16.6123 33.603 16.8853 33.5078C17.1584 33.4126 17.3971 33.2386 17.5712 33.0077C17.7454 32.7769 17.8472 32.4996 17.8638 32.2109C17.8748 32.019 17.8479 31.8269 17.7847 31.6454C17.7214 31.464 17.6229 31.2967 17.495 31.1534C17.3671 31.01 17.2121 30.8932 17.039 30.8097C16.8659 30.7262 16.6781 30.6777 16.4862 30.6669Z"
      fill="currentColor"
    />
    <path
      d="M14.8655 40.4987C14.6528 40.6061 14.4768 40.7742 14.3597 40.9817C14.2426 41.1892 14.1896 41.4267 14.2075 41.6643C14.2254 41.9018 14.3132 42.1288 14.4601 42.3164C14.6069 42.504 14.806 42.6439 15.0323 42.7184C15.2586 42.7929 15.5019 42.7986 15.7315 42.7348C15.961 42.671 16.1665 42.5406 16.3219 42.36C16.4774 42.1795 16.5758 41.9569 16.6048 41.7204C16.6337 41.4839 16.5919 41.2442 16.4847 41.0315C16.4134 40.8901 16.3149 40.7642 16.195 40.6609C16.075 40.5576 15.9359 40.479 15.7855 40.4295C15.6351 40.38 15.4765 40.3607 15.3186 40.3725C15.1607 40.3844 15.0068 40.4273 14.8655 40.4987Z"
      fill="currentColor"
    />
    <path
      d="M20.5504 27.6444C20.8352 27.8311 21.1689 27.9291 21.5094 27.926C21.8498 27.923 22.1817 27.8191 22.4632 27.6274C22.7446 27.4358 22.9628 27.165 23.0904 26.8493C23.2179 26.5336 23.2489 26.1872 23.1796 25.8538C23.1102 25.5205 22.9436 25.2152 22.7007 24.9766C22.4579 24.7379 22.1497 24.5766 21.8152 24.5131C21.4807 24.4496 21.1349 24.4867 20.8215 24.6197C20.508 24.7528 20.2411 24.9757 20.0544 25.2604C19.9304 25.4495 19.8449 25.6612 19.8027 25.8834C19.7605 26.1055 19.7625 26.3338 19.8086 26.5552C19.8546 26.7766 19.9438 26.9867 20.0711 27.1736C20.1984 27.3605 20.3613 27.5205 20.5504 27.6444Z"
      fill="currentColor"
    />
    <path
      d="M38.586 20.0139C38.8285 20.1721 39.1124 20.2548 39.4019 20.2516C39.6914 20.2485 39.9734 20.1596 40.2124 19.9961C40.4513 19.8327 40.6364 19.6021 40.7444 19.3335C40.8523 19.0648 40.8781 18.7702 40.8186 18.4869C40.7592 18.2036 40.617 17.9443 40.4102 17.7417C40.2034 17.5392 39.9411 17.4025 39.6566 17.349C39.3721 17.2954 39.0781 17.3275 38.8118 17.441C38.5455 17.5545 38.3188 17.7444 38.1604 17.9867C38.0552 18.1478 37.9827 18.3279 37.9471 18.517C37.9116 18.7061 37.9136 18.9003 37.9531 19.0885C37.9926 19.2768 38.0689 19.4554 38.1775 19.6142C38.2861 19.773 38.4249 19.9088 38.586 20.0139Z"
      fill="currentColor"
    />
    <path
      d="M41.5878 14.8492C41.7446 14.9518 41.9282 15.0055 42.1155 15.0037C42.3028 15.0019 42.4853 14.9446 42.6401 14.8391C42.7948 14.7336 42.9148 14.5845 42.9848 14.4108C43.0549 14.2371 43.0719 14.0466 43.0336 13.8632C42.9954 13.6799 42.9037 13.512 42.77 13.3807C42.6364 13.2495 42.4668 13.1608 42.2828 13.126C42.0988 13.0911 41.9086 13.1115 41.7362 13.1847C41.5638 13.2579 41.4169 13.3806 41.3142 13.5372C41.246 13.6413 41.1989 13.7579 41.1758 13.8801C41.1526 14.0024 41.1537 14.1281 41.1791 14.2499C41.2045 14.3718 41.2537 14.4874 41.3238 14.5903C41.394 14.6931 41.4837 14.7811 41.5878 14.8492Z"
      fill="currentColor"
    />
    <path
      d="M38.8164 29.155C38.4272 29.1236 38.0357 29.1703 37.6649 29.2924C37.2941 29.4145 36.9514 29.6094 36.657 29.8658C36.3626 30.1222 36.1224 30.4349 35.9505 30.7854C35.7787 31.136 35.6786 31.5173 35.6562 31.9071C35.6338 32.2968 35.6895 32.6871 35.8201 33.055C35.9507 33.4229 36.1535 33.7611 36.4166 34.0495C36.6797 34.3379 36.9978 34.5708 37.3522 34.7346C37.7066 34.8983 38.0902 34.9896 38.4804 35.003H38.65C39.2149 35.0027 39.7677 34.839 40.2416 34.5316C40.7156 34.2243 41.0906 33.7863 41.3213 33.2707C41.552 32.755 41.6285 32.1836 41.5418 31.6254C41.455 31.0671 41.2087 30.5459 40.8324 30.1246C40.5775 29.8373 40.2679 29.6036 39.9218 29.4371C39.5757 29.2707 39.1999 29.1748 38.8164 29.155Z"
      fill="currentColor"
    />
    <path
      d="M25.666 27.4414C25.9855 28.0849 26.5303 28.5886 27.1969 28.8566C27.8635 29.1247 28.6053 29.1385 29.2814 28.8953C29.9575 28.6522 30.5206 28.1691 30.8638 27.5378C31.207 26.9066 31.3062 26.1713 31.1426 25.4717C30.9791 24.7721 30.5641 24.157 29.9767 23.7433C29.3893 23.3296 28.6703 23.1463 27.9565 23.228C27.2427 23.3098 26.5838 23.651 26.1052 24.1869C25.6265 24.7227 25.3614 25.4157 25.3604 26.1342C25.3593 26.5877 25.4639 27.0353 25.666 27.4414Z"
      fill="currentColor"
    />
    <path
      d="M48.6255 23.6891C48.8382 23.5818 49.0143 23.4138 49.1315 23.2065C49.2488 22.9991 49.3019 22.7616 49.2842 22.5241C49.2665 22.2865 49.1788 22.0595 49.0322 21.8718C48.8855 21.6841 48.6865 21.5441 48.4603 21.4694C48.2341 21.3947 47.9909 21.3888 47.7613 21.4524C47.5317 21.5159 47.3261 21.6461 47.1705 21.8265C47.0149 22.0068 46.9163 22.2293 46.887 22.4657C46.8578 22.7021 46.8993 22.9419 47.0063 23.1547C47.0775 23.2962 47.1758 23.4223 47.2957 23.5257C47.4156 23.6292 47.5547 23.708 47.7051 23.7576C47.8555 23.8072 48.0142 23.8267 48.1722 23.815C48.3301 23.8032 48.4841 23.7604 48.6255 23.6891Z"
      fill="currentColor"
    />
    <path
      d="M41.2001 24.622C40.896 24.7752 40.6442 25.0151 40.4764 25.3114C40.3086 25.6077 40.2325 25.9471 40.2576 26.2866C40.2828 26.6262 40.408 26.9507 40.6176 27.2191C40.8271 27.4875 41.1115 27.6877 41.4349 27.7944C41.7582 27.9012 42.1059 27.9096 42.4341 27.8187C42.7622 27.7278 43.0561 27.5417 43.2784 27.2838C43.5007 27.0259 43.6416 26.7079 43.6832 26.3699C43.7248 26.032 43.6653 25.6893 43.5121 25.3852C43.4104 25.1833 43.27 25.0034 43.0987 24.8557C42.9275 24.7081 42.7289 24.5956 42.5142 24.5248C42.2995 24.4539 42.073 24.426 41.8475 24.4427C41.622 24.4594 41.4021 24.5203 41.2001 24.622Z"
      fill="currentColor"
    />
    <path
      d="M31.6349 14.4077C31.8724 14.4211 32.1086 14.3639 32.3137 14.2433C32.5187 14.1227 32.6835 13.9441 32.7872 13.73C32.8909 13.5158 32.9289 13.2758 32.8963 13.0402C32.8638 12.8045 32.7623 12.5837 32.6045 12.4057C32.4467 12.2277 32.2398 12.1003 32.0097 12.0397C31.7797 11.9791 31.5368 11.988 31.3118 12.0652C31.0868 12.1424 30.8897 12.2845 30.7453 12.4736C30.6009 12.6627 30.5157 12.8903 30.5005 13.1277C30.4903 13.2863 30.5117 13.4455 30.5633 13.5958C30.6149 13.7462 30.6957 13.8849 30.8012 14.0039C30.9066 14.1229 31.0346 14.2198 31.1777 14.2891C31.3208 14.3584 31.4762 14.3987 31.6349 14.4077Z"
      fill="currentColor"
    />
    <path
      d="M31.6242 21.9903C31.964 22.0094 32.3019 21.9272 32.5951 21.7543C32.8883 21.5814 33.1237 21.3254 33.2715 21.0188C33.4192 20.7121 33.4728 20.3685 33.4253 20.0314C33.3779 19.6944 33.2316 19.3789 33.0049 19.125C32.7782 18.871 32.4814 18.69 32.1518 18.6047C31.8223 18.5194 31.4748 18.5338 31.1534 18.6459C30.832 18.758 30.5511 18.9629 30.3461 19.2347C30.1411 19.5064 30.0213 19.8329 30.0018 20.1727C29.976 20.6288 30.1324 21.0766 30.4367 21.4174C30.7409 21.7582 31.168 21.9643 31.6242 21.9903Z"
      fill="currentColor"
    />
    <path
      d="M22.2955 39.5645C22.5995 39.4112 22.8513 39.1712 23.019 38.8748C23.1866 38.5784 23.2626 38.2389 23.2373 37.8993C23.212 37.5597 23.0865 37.2353 22.8768 36.967C22.6671 36.6987 22.3825 36.4986 22.0591 36.3921C21.7356 36.2856 21.3879 36.2773 21.0598 36.3684C20.7316 36.4596 20.4379 36.6459 20.2157 36.904C19.9936 37.1621 19.8529 37.4802 19.8116 37.8182C19.7702 38.1562 19.83 38.4989 19.9835 38.8029C20.1895 39.2101 20.5486 39.519 20.982 39.6618C21.4154 39.8046 21.8878 39.7696 22.2955 39.5645Z"
      fill="currentColor"
    />
    <path
      d="M32.7346 24.5019C32.3771 25.0471 32.2117 25.6959 32.2645 26.3456C32.3173 26.9954 32.5853 27.609 33.0262 28.0893C33.467 28.5695 34.0554 28.8891 34.6982 28.9973C35.3411 29.1055 36.0017 28.9962 36.5754 28.6867C37.1492 28.3771 37.6033 27.8851 37.8659 27.2884C38.1285 26.6918 38.1846 26.0246 38.0253 25.3924C37.866 24.7603 37.5004 24.1993 36.9864 23.7984C36.4723 23.3974 35.8393 23.1794 35.1874 23.1787C34.7008 23.1781 34.2217 23.2987 33.7935 23.5298C33.3652 23.7608 33.0013 24.0949 32.7346 24.5019Z"
      fill="currentColor"
    />
    <path
      d="M37.8272 36.7467C37.5677 36.2255 37.1593 35.7932 36.6537 35.5046C36.1481 35.2159 35.5681 35.0841 34.9874 35.1257C34.4067 35.1673 33.8515 35.3805 33.3922 35.7382C32.9329 36.096 32.5903 36.5822 32.4078 37.135C32.2253 37.6878 32.2112 38.2824 32.3673 38.8433C32.5234 39.4042 32.8426 39.906 33.2844 40.2851C33.7262 40.6643 34.2708 40.9035 34.8488 40.9726C35.4269 41.0417 36.0125 40.9374 36.5312 40.6731C37.2226 40.3231 37.7471 39.7134 37.99 38.9776C38.2329 38.2417 38.1744 37.4395 37.8272 36.7467Z"
      fill="currentColor"
    />
    <path
      d="M42.9427 36.5422C42.6581 36.3553 42.3244 36.2571 41.984 36.2598C41.6435 36.2626 41.3114 36.3663 41.0299 36.5577C40.7483 36.7492 40.5298 37.0199 40.4021 37.3355C40.2744 37.6511 40.2431 37.9975 40.3122 38.331C40.3814 38.6644 40.5478 38.9698 40.7906 39.2086C41.0333 39.4474 41.3414 39.6088 41.6758 39.6726C42.0103 39.7363 42.3562 39.6994 42.6697 39.5665C42.9832 39.4336 43.2503 39.2108 43.4371 38.9262C43.5612 38.7372 43.6469 38.5256 43.6892 38.3035C43.7315 38.0814 43.7296 37.8531 43.6837 37.6318C43.6378 37.4104 43.5487 37.2002 43.4216 37.0133C43.2945 36.8263 43.1317 36.6662 42.9427 36.5422Z"
      fill="currentColor"
    />
    <path
      d="M48.5494 32.1434C48.5657 31.8547 48.4961 31.5677 48.3492 31.3185C48.2024 31.0694 47.985 30.8694 47.7246 30.7438C47.4641 30.6182 47.1722 30.5726 46.8859 30.6129C46.5995 30.6531 46.3315 30.7773 46.1158 30.9698C45.9 31.1623 45.7461 31.4145 45.6736 31.6944C45.6012 31.9744 45.6133 32.2695 45.7085 32.5426C45.8037 32.8156 45.9778 33.0543 46.2086 33.2285C46.4394 33.4026 46.7167 33.5044 47.0054 33.521C47.1973 33.532 47.3894 33.5052 47.5709 33.4419C47.7524 33.3786 47.9196 33.2802 48.063 33.1522C48.2064 33.0243 48.3231 32.8693 48.4066 32.6962C48.4901 32.5231 48.5386 32.3353 48.5494 32.1434Z"
      fill="currentColor"
    />
    <path
      d="M52.6003 31.1022C52.4132 31.0914 52.2272 31.1363 52.0657 31.2313C51.9042 31.3262 51.7745 31.467 51.693 31.6356C51.6115 31.8043 51.5818 31.9934 51.6078 32.1789C51.6337 32.3644 51.7141 32.5381 51.8387 32.678C51.9633 32.8178 52.1267 32.9176 52.308 32.9646C52.4893 33.0117 52.6806 33.0039 52.8575 32.9423C53.0344 32.8806 53.189 32.7679 53.3019 32.6184C53.4147 32.4688 53.4807 32.2892 53.4915 32.1022C53.5055 31.8515 53.4195 31.6055 53.2525 31.418C53.0854 31.2306 52.8509 31.117 52.6003 31.1022Z"
      fill="currentColor"
    />
    <path
      d="M48.7868 40.4943C48.5875 40.3638 48.354 40.2952 48.1157 40.2974C47.8775 40.2996 47.6453 40.3723 47.4484 40.5065C47.2515 40.6406 47.0988 40.8301 47.0095 41.051C46.9203 41.2719 46.8986 41.5143 46.9471 41.7476C46.9957 41.9808 47.1123 42.1944 47.2822 42.3614C47.4521 42.5284 47.6677 42.6413 47.9018 42.6858C48.1358 42.7303 48.3778 42.7043 48.5972 42.6113C48.8165 42.5183 49.0033 42.3623 49.134 42.1631C49.2211 42.0308 49.2811 41.8826 49.3108 41.7271C49.3405 41.5715 49.3391 41.4117 49.3069 41.2566C49.2746 41.1016 49.2121 40.9544 49.1228 40.8236C49.0336 40.6928 48.9194 40.5809 48.7868 40.4943Z"
      fill="currentColor"
    />
    <path
      d="M21.9063 49.3374C21.7497 49.2346 21.5661 49.1806 21.3788 49.1822C21.1915 49.1837 21.0089 49.2408 20.854 49.3461C20.6991 49.4514 20.5789 49.6003 20.5086 49.774C20.4384 49.9476 20.4212 50.1382 20.4592 50.3216C20.4972 50.505 20.5888 50.673 20.7223 50.8044C20.8558 50.9358 21.0252 51.0247 21.2092 51.0598C21.3932 51.0948 21.5835 51.0746 21.756 51.0015C21.9285 50.9285 22.0755 50.806 22.1783 50.6494C22.3159 50.4392 22.3645 50.1831 22.3135 49.9372C22.2625 49.6912 22.1161 49.4756 21.9063 49.3374Z"
      fill="currentColor"
    />
    <path
      d="M41.7592 49.2381C41.5919 49.3225 41.4533 49.4545 41.3611 49.6176C41.2688 49.7806 41.227 49.9674 41.2409 50.1542C41.2548 50.3411 41.3238 50.5196 41.4392 50.6672C41.5545 50.8148 41.7111 50.9249 41.889 50.9836C42.067 51.0422 42.2583 51.0468 42.4388 50.9967C42.6193 50.9466 42.781 50.844 42.9032 50.7021C43.0255 50.5601 43.1029 50.3851 43.1257 50.1991C43.1484 50.0131 43.1156 49.8246 43.0312 49.6573C42.9186 49.4334 42.7218 49.2632 42.4839 49.1843C42.246 49.1054 41.9864 49.1242 41.7624 49.2365L41.7592 49.2381Z"
      fill="currentColor"
    />
    <path
      d="M30.7574 39.6796C31.1149 39.1343 31.2803 38.4855 31.2275 37.8356C31.1746 37.1858 30.9065 36.5722 30.4656 36.0919C30.0247 35.6116 29.4361 35.2922 28.7932 35.1841C28.1502 35.076 27.4896 35.1855 26.9159 35.4952C26.3422 35.8049 25.8882 36.2971 25.6257 36.8939C25.3633 37.4908 25.3075 38.1581 25.467 38.7902C25.6266 39.4224 25.9925 39.9832 26.5068 40.384C27.021 40.7847 27.6543 41.0025 28.3062 41.0028C28.7926 41.0031 29.2713 40.8823 29.6993 40.6513C30.1272 40.4203 30.4909 40.0863 30.7574 39.6796Z"
      fill="currentColor"
    />
    <path
      d="M24.9066 44.1742C24.6646 44.0158 24.3811 43.9328 24.0919 43.9356C23.8027 43.9384 23.5209 44.0268 23.282 44.1898C23.0431 44.3527 22.8579 44.5828 22.7497 44.851C22.6415 45.1192 22.6153 45.4134 22.6744 45.6965C22.7334 45.9796 22.875 46.2388 23.0813 46.4414C23.2877 46.644 23.5494 46.7809 23.8336 46.8348C24.1177 46.8886 24.4114 46.857 24.6775 46.744C24.9437 46.631 25.1704 46.4416 25.329 46.1998C25.4343 46.039 25.507 45.8591 25.5428 45.6703C25.5786 45.4815 25.5768 45.2875 25.5376 45.0994C25.4983 44.9113 25.4224 44.7327 25.3141 44.574C25.2059 44.4152 25.0674 44.2794 24.9066 44.1742Z"
      fill="currentColor"
    />
    <path
      d="M31.7322 49.7788C31.4943 49.7653 31.2578 49.8226 31.0525 49.9435C30.8472 50.0644 30.6823 50.2434 30.5788 50.458C30.4752 50.6725 30.4376 50.913 30.4707 51.149C30.5037 51.3849 30.606 51.6057 30.7646 51.7836C30.9232 51.9614 31.1309 52.0882 31.3616 52.1479C31.5922 52.2077 31.8354 52.1977 32.0604 52.1193C32.2854 52.0409 32.482 51.8975 32.6255 51.7073C32.769 51.5171 32.8529 51.2887 32.8666 51.0508C32.8845 50.7318 32.7751 50.4187 32.5625 50.1802C32.3498 49.9417 32.0512 49.7974 31.7322 49.7788Z"
      fill="currentColor"
    />
    <path
      d="M31.7456 42.198C31.4057 42.1774 31.0673 42.2581 30.7733 42.4298C30.4793 42.6015 30.2428 42.8565 30.0937 43.1627C29.9446 43.4688 29.8897 43.8123 29.9358 44.1497C29.9819 44.487 30.1271 44.8031 30.3528 45.058C30.5786 45.3129 30.8749 45.4951 31.2043 45.5816C31.5336 45.6681 31.8812 45.6549 32.2031 45.5439C32.525 45.4328 32.8067 45.2288 33.0126 44.9576C33.2185 44.6864 33.3394 44.3603 33.36 44.0204C33.3738 43.7947 33.3431 43.5684 33.2694 43.3546C33.1957 43.1408 33.0806 42.9437 32.9307 42.7744C32.7807 42.6051 32.5989 42.4671 32.3955 42.3682C32.1922 42.2693 31.9713 42.2114 31.7456 42.198Z"
      fill="currentColor"
    />
    <path
      d="M38.7876 44.0572C38.5295 44.1885 38.3161 44.3934 38.1744 44.646C38.0327 44.8986 37.9692 45.1875 37.9918 45.4762C38.0143 45.765 38.1221 46.0405 38.3013 46.268C38.4805 46.4955 38.7231 46.6648 38.9986 46.7543C39.274 46.8439 39.5698 46.8498 39.8485 46.7712C40.1272 46.6926 40.3764 46.5331 40.5645 46.3129C40.7526 46.0927 40.8712 45.8216 40.9052 45.534C40.9392 45.2464 40.8871 44.9552 40.7556 44.6972C40.58 44.3521 40.2746 44.0909 39.9065 43.9709C39.5383 43.8509 39.1376 43.8819 38.7924 44.0572H38.7876Z"
      fill="currentColor"
    />
  </svg>
</template>
