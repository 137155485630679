import Cookies from 'js-cookie';

export const useCookie = () => {
  const getCookie = key => {
    return Cookies.get(key);
  };

  const setCookie = (key, value, expires = 365) => {
    Cookies.set(key, value, { expires });
  };

  const removeCookie = key => {
    Cookies.remove(key);
  };

  return {
    getCookie,
    setCookie,
    removeCookie,
  };
};
