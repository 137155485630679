<template>
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4393 29.9998C15.9793 29.9998 15.5393 29.8098 15.2243 29.4748L7.1193 20.8431C6.48763 20.1731 6.52263 19.1181 7.19263 18.4881C7.8643 17.8581 8.9193 17.8914 9.54763 18.5614L16.4226 25.8798L30.436 10.5431C31.0593 9.86145 32.1126 9.81645 32.7926 10.4364C33.471 11.0564 33.5176 12.1114 32.8976 12.7898L17.6693 29.4564C17.3576 29.7998 16.9143 29.9964 16.451 29.9998H16.4393Z"
      fill="currentColor"
    />
  </svg>
</template>
