import countdownTimer from 'countdown-js';
import { ref, unref } from 'vue';

export const useCountdown = () => {
  const initCountdownTimer = (endTime, endText = 'expired') => {
    const timer = ref(null);

    countdownTimer.timer(
      endTime,
      timeLeft => {
        const time = `
        ${timeLeft.days !== 0 ? `${timeLeft.days.toLocaleString(undefined, { minimumIntegerDigits: 2 })} :` : ''}
        ${timeLeft.hours !== 0 ? `${timeLeft.hours.toLocaleString(undefined, { minimumIntegerDigits: 2 })} :` : ''}
        ${timeLeft.minutes && `${timeLeft.minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })} :`}
        ${timeLeft.seconds && `${timeLeft.seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`}`;

        timer.value = time.replace(/\s/g, '');
      },
      () => {
        timer.value = endText;
      },
    );

    return unref(timer);
  };

  return {
    initCountdownTimer,
  };
};
