<template>
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="30.5" stroke="currentColor" stroke-width="3" />
    <rect x="11" y="18" width="10.5217" height="10.5728" fill="currentColor" />
    <rect x="11" y="29.2134" width="10.5217" height="10.5728" fill="currentColor" />
    <rect x="22.1592" y="29.2134" width="10.5217" height="10.5728" fill="currentColor" />
    <rect x="33.3184" y="29.2134" width="10.5217" height="10.5728" fill="currentColor" />
    <rect x="33.3184" y="40.4272" width="10.5217" height="10.5728" fill="currentColor" />
    <rect x="44.4785" y="29.2134" width="10.5217" height="10.5728" fill="currentColor" />
  </svg>
</template>
