<script>
import { computed } from 'vue';

export default {
  props: {
    disabled: {
      default: false,
    },
    labelLeft: {
      default: '',
    },
    labelRight: {
      default: '',
    },
    value: {
      default: true,
    },
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      },
    });

    return {
      inputValue,
    };
  },
};
</script>

<template>
  <div class="container">
    <input id="switch" class="input" type="checkbox" :disabled="disabled" hidden="hidden" v-model="inputValue" />
    <label class="label" for="switch">
      <span class="labelLeft" v-if="labelLeft">{{ labelLeft }}</span>
      <div class="switch" />
      <span class="labelRight" v-if="labelRight">{{ labelRight }}</span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.container {
  position: relative;
}

.switch {
  background: #dfd9ea;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  margin: 0 0.5rem;
  position: relative;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: 50px;

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 22px;
    height: 22px;
    background: #fafafa;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:active::before {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
  }
}

.label {
  font-size: 0.875rem;
}

.labelLeft {
  font-weight: 500;
}

.labelLeft,
.labelRight {
  transition: font-weight 0.25s ease-in-out;
}

.input {
  &:checked {
    & + .label {
      .labelRight {
        font-weight: 500;
      }

      .labelLeft {
        font-weight: normal;
      }

      .switch {
        background: $color-primary;

        &::before {
          left: 27px;
          background: #fff;
        }

        &:active::before {
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
        }
      }
    }
  }
}
</style>
