import { ref } from 'vue';

export const useFetch = () => {
  const fetchData = ({ url, method, body = null, options = null, headers = null }) => {
    const result = ref(null);
    const loading = ref(false);
    const error = ref(null);

    loading.value = true;

    result.value = fetch(url, {
      method,
      body,
      options: {
        ...options,
      },
      headers: {
        ...headers,
      },
    })
      .then(async res => {
        const contentType = res.headers.get('content-type');
        let resData;

        if (contentType && contentType.includes('application/json')) {
          resData = await res.json();
        } else {
          resData = await res.text();
        }

        if (res.status >= 400 && res.status < 600) {
          if (resData) {
            const message = resData?.message || resData?.error?.message || resData;
            throw new Error(message);
          }

          throw new Error('Network error: could not fetch request...');
        }

        return resData;
      })
      .catch(err => {
        console.error(err.message);
        error.value = err.message;
      })
      .finally(() => {
        setTimeout(() => {
          loading.value = false;
        }, 500);
      });

    return {
      loading,
      error,
      result,
    };
  };

  return {
    fetchData,
  };
};
