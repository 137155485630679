<script>
import { computed, ref } from 'vue';
import TokensPurchase from './TokensPurchase/TokensPurchase';
import TokensClaim from './TokensClaim/TokensClaim';
import Switch from '../Switch';

export default {
  components: {
    Switch,
    TokensPurchase,
    TokensClaim,
  },
  props: {
    tokens: Array,
    walletAddress: String,
    rounds: Array,
    statusPerRound: Object,
    maxTokensPerPerson: Number,
    maxTokensPerPersonFcnft: Number,
    hasFcnft: {
      type: Boolean,
      default: false,
    },
    isTokensActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const toggleValue = ref(true);

    const inputValue = computed({
      get() {
        return toggleValue.value;
      },
      set(value) {
        toggleValue.value = value;
      },
    });

    const heading = computed(() => {
      if (!inputValue.value) {
        return 'Tokens Purchased';
      }

      return 'Tokens Claimed';
    });

    const handleSwitchInput = event => {
      inputValue.value = event;
    };

    return {
      heading,
      inputValue,
      toggleValue,

      handleSwitchInput,
    };
  },
};
</script>

<template>
  <div class="tokens-container">
    <header class="tokens-container__header">
      <div class="tokens-container__group">
        <h3 class="tokens-container__heading">
          {{ heading }}
        </h3>
        <Switch label-left="Purchased" label-right="Claimed" @input="handleSwitchInput" />
      </div>
    </header>
    <div class="tokens-container__content">
      <TokensPurchase
        :tokens="tokens"
        :walletAddress="walletAddress"
        :rounds="rounds"
        :statusPerRound="statusPerRound"
        :maxTokensPerPerson="maxTokensPerPerson"
        :maxTokensPerPersonFcnft="maxTokensPerPersonFcnft"
        :hasFcnft="hasFcnft"
        :isTokensActive="isTokensActive"
        v-show="!toggleValue"
      />
      <TokensClaim v-show="toggleValue" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.tokens-container {
  &__header {
    margin-bottom: 2rem;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 768px) {
    &__heading {
      margin: 0;
    }

    &__group {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
