<script>
import { ref } from 'vue';
import InfoIcon from './icons/InfoIcon.vue';

export default {
  components: {
    InfoIcon,
  },
  props: {
    tooltipText: String,
  },
  emits: ['click'],
  setup(props, { emit }) {
    const showInstructionsTooltip = ref(false);

    const setInstructionsShowTooltip = val => {
      showInstructionsTooltip.value = val;
    };

    const onClick = () => {
      emit('click');
    };

    return {
      showInstructionsTooltip,

      setInstructionsShowTooltip,
      onClick,
    };
  },
};
</script>

<template>
  <div class="info-button-wrapper">
    <div class="info-button-wrapper__tooltip info-tooltip">
      <div class="info-tooltip__tooltip" v-if="showInstructionsTooltip">
        <div class="info-tooltip__message">{{ tooltipText }}</div>
        <div class="info-tooltip__leg" />
      </div>
    </div>
    <InfoIcon
      class="info-button-wrapper__button info-button"
      @mouseenter="setInstructionsShowTooltip(true)"
      @mouseleave="setInstructionsShowTooltip(false)"
      @click="onClick()"
    />
  </div>
</template>

<style lang="scss">
@import '../assets/scss/variables';
@import '../assets/scss/mixins';

.info-button-wrapper {
  position: relative;

  &__tooltip {
    position: absolute;
  }

  &__button {
    &:hover {
      cursor: pointer;
    }
  }
}

.info-tooltip {
  &__tooltip {
    position: absolute;
    left: -88px;
    top: -40px;
    width: 200px;
    text-align: center;
  }

  &__message {
    border-radius: 5px;
    background-color: #101426;
    font-size: 0.8rem;
    color: $color-white;
    padding: 0.5rem;
  }

  &__leg {
    width: 10px;
    margin: auto;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #101426;
  }
}
</style>
