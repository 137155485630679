<script>
import { ref, watch } from 'vue';
import { useState } from '../../../composables/useState';
import { appConfig } from '../../../config';
import Button from '../../Button.vue';
import Modal from '../../Modal.vue';
import CopyText from '../../CopyText.vue';

export default {
  name: 'WalletModal',
  components: {
    Button,
    Modal,
    CopyText,
  },
  setup() {
    const poolUrl = appConfig.walletPool.url;
    const { state, setState } = useState();
    const showTooltip = ref(false);
    const showModal = ref(state.showWalletModal);
    const walletKey = ref(state.user_stake_key);

    const closeWalletModal = () => {
      setState({ showWalletModal: false });
    };

    const gotoWallet = () => {
      window.open(`${poolUrl}/${walletKey.value}`, '_blank');
    };

    watch([() => state.showWalletModal], ([show]) => {
      showModal.value = show;
    });

    return {
      showTooltip,
      showModal,
      walletKey,
      closeWalletModal,
      gotoWallet,
    };
  },
};
</script>

<template>
  <div>
    <Modal :zIndex="100" :closable="true" @press-close="closeWalletModal()" :show="showModal">
      <template v-slot:content>
        <div class="wallet-modal">
          <h4 class="wallet-modal__title">Wallet Linking</h4>
          <div class="wallet-modal__description">
            <p>
              <strong>The same wallet must be used to transfer all funds during the Empowa Token Sale.</strong
              ><br /><br />Your first transaction will link your wallet with the Token Sale Dashboard. Future
              transactions will then only be accepted from your linked wallet. <br /><br /><strong>NOTE:</strong> The
              same wallet cannot be linked to multiple dashboard accounts.
            </p>
            <div v-if="walletKey && walletKey !== ''">
              <p class="mb-8">Your linked wallet key is:</p>
              <CopyText :text="walletKey" />
              <Button class="mt-16" @click="gotoWallet()">View on Pool.pm</Button>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.wallet-modal {
  &__title {
    line-height: 2rem;
    color: $color-primary;
  }

  &__description {
    height: 330px;
    overflow-y: auto;
    padding-right: 2rem;

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
</style>
