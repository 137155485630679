const {
  VUE_APP_ENV,
  VUE_APP_MAINTENANCE,
  VUE_APP_EMPOWA_API_URL,
  VUE_APP_RESTDB_TOKEN,
  VUE_APP_RESTDB_API_URL,
  VUE_APP_RESTDB_API_URL_TOKENS,
  VUE_APP_RESTDB_API_URL_CLAIMS,
  VUE_APP_BLOCKPASS_TOKEN,
  VUE_APP_BLOCKPASS_API_URL,
  VUE_APP_BLOCKFROST_TOKEN,
  VUE_APP_BLOCKFROST_API_URL,
} = process.env;

export const appConfig = {
  app: {
    url: window.location.origin,
    isMaintenanceMode: !!(VUE_APP_MAINTENANCE && VUE_APP_MAINTENANCE === 'true'),
    env: VUE_APP_ENV,
  },
  api: {
    emailLink: `${VUE_APP_EMPOWA_API_URL}/email-link`,
    emailCheck: `${VUE_APP_EMPOWA_API_URL}/email-check`,
    purchaser: VUE_APP_RESTDB_API_URL,
    tokens: VUE_APP_RESTDB_API_URL_TOKENS,
    claims: VUE_APP_RESTDB_API_URL_CLAIMS,
    blockpass: VUE_APP_BLOCKPASS_API_URL,
    assets: stakeKey => `${VUE_APP_BLOCKFROST_API_URL}/accounts/${stakeKey}/addresses/assets`,
    singleAsset: asset => `${VUE_APP_BLOCKFROST_API_URL}/assets/${asset}`,
  },
  tokens: {
    restDb: VUE_APP_RESTDB_TOKEN,
    blockPass: VUE_APP_BLOCKPASS_TOKEN,
    blockFrost: VUE_APP_BLOCKFROST_TOKEN,
  },
  tokensale: {
    rounds: [
      {
        roundNr: 0,
        min: 10,
        max: 106000,
        label: 'Founding Community',
        startDate: '2022-01-31 12:00',
        endDate: '2022-03-06 12:00',
      },
      {
        roundNr: 1,
        min: 100,
        max: 1000,
        label: 'Round 1',
        startDate: '2022-01-31 12:00',
        endDate: '2022-02-07 12:00',
      },
      {
        roundNr: 2,
        min: 100,
        max: 1000,
        label: 'Round 2',
        startDate: '2022-02-07 12:00',
        endDate: '2022-02-14 12:00',
      },
      {
        roundNr: 3,
        min: 10,
        max: 4000,
        label: 'Round 3',
        startDate: '2022-02-14 12:00',
        endDate: '2022-02-21 12:00',
      },
      {
        roundNr: 4,
        min: 10,
        max: 100000,
        label: 'Round 4',
        startDate: '2022-02-21 12:00',
        endDate: '2022-03-06 12:00',
      },
    ],
    claimRounds: [
      {
        roundNr: 1,
        label: 'Round 1',
        startDate: '2022-04-01 12:00',
        endDate: '2022-05-15 12:00',
      },
      {
        roundNr: 2,
        label: 'Round 2',
        startDate: '2022-05-15 12:00',
        endDate: '2022-06-15 12:00',
      },
      {
        roundNr: 3,
        label: 'Round 3',
        startDate: '2022-06-15 12:00',
        endDate: '2022-07-15 12:00',
      },
      {
        roundNr: 4,
        label: 'Round 4',
        startDate: '2022-07-15 12:00',
        endDate: '2022-08-15 12:00',
      },
      {
        roundNr: 5,
        label: 'Round 5',
        startDate: '2022-08-15 12:00',
        endDate: '2022-09-15 12:00',
      },
      {
        roundNr: 6,
        label: 'Round 6',
        startDate: '2022-09-15 12:00',
        endDate: '2022-10-15 12:00',
      },
    ],
    statusPerRound: {
      submitted: {
        label: 'Submitted',
        dataKeysRequired: ['round', 'quantity'],
      },
      active: {
        label: 'Submitted',
        dataKeysRequired: ['round', 'quantity', 'ada_amount', 'purchase_window_starttime'],
      },
      complete: {
        label: 'Submitted',
        dataKeysRequired: ['round', 'quantity', 'ada_amount', 'purchase_window_starttime', 'transaction_id'],
      },
      incomplete: {
        label: 'Submitted',
        dataKeysRequired: ['round', 'quantity', 'purchase_window_starttime', 'transaction_id'],
      },
    },
    maxTokenPerPerson: 106000,
    maxTokenPerPersonFcnft: 106000,
  },
  blockpass: {
    env: 'prod',
    clientId: 'empowa_token_sale',
  },
  fcNft: {
    unit: 'ccb2b25e5fd18224ea931a3812e5888716d9c08cd8871ff0ab3dc2fa',
  },
  walletPool: {
    url: 'https://pool.pm',
  },
};
