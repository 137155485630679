<script>
import ArrowDownIcon from './icons/ArrowDownIcon';
import CheckIcon from './icons/CheckIcon';

export default {
  name: 'AccordionBlock',
  components: {
    ArrowDownIcon,
    CheckIcon,
  },
  props: {
    variant: {
      type: String,
      value: ['primary', 'secondary'],
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      value: 'small',
    },
  },
  data() {
    return {
      showAccordion: false,
    };
  },
  computed: {
    modiferClasses() {
      const { variant, size } = this;
      let classes = '';

      if (variant) {
        classes += ` accordion--${variant}`;
      }

      if (size) {
        classes += ` accordion--${size}`;
      }

      return classes;
    },
  },
  methods: {
    toggleAccordion() {
      this.showAccordion = !this.showAccordion;
    },
  },
  setup(props, { slots }) {
    const iconSlot = slots.title;
    const titleSlot = slots.title;
    const subtitleSlot = slots.subtitle;
    const contentSlot = slots.default;
    const descriptionSlot = slots.description;

    return {
      iconSlot,
      titleSlot,
      subtitleSlot,
      descriptionSlot,
      contentSlot,
    };
  },
};
</script>

<template>
  <div class="accordion-block" :class="[modiferClasses, { 'is-open': showAccordion, 'is-completed': isCompleted }]">
    <div class="accordion-block__main" @click="toggleAccordion">
      <div v-if="iconSlot" class="accordion-block__logo">
        <slot name="icon" />
      </div>
      <header class="accordion-block__header">
        <h3 class="accordion-block__subheading" v-if="subtitleSlot"><slot name="subtitle" /></h3>
        <h2 class="accordion-block__heading" v-if="titleSlot"><slot name="title" /></h2>
        <p class="accordion-block__description"><slot name="description" /></p>
      </header>
      <CheckIcon v-if="isCompleted" class="accordion-block__icon" />
      <ArrowDownIcon v-else class="accordion-block__icon" />
    </div>
    <div class="accordion-block__sub">
      <div class="accordion-block__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.accordion-block {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 23px rgba(0, 0, 0, 0.06), 0 28px 92px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  width: 100%;

  &__main {
    align-items: center;
    background-color: $color-white;
    cursor: pointer;
    display: flex;
    padding: 1rem;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

    &:hover {
      background-color: darken($color-white, 3%);
    }
  }

  &__sub {
    background-color: $color-white;
    max-height: 0;
    overflow: hidden;
    transition: background-color 0.25s ease-in-out, max-height 0.5s ease-in-out;
  }

  &__content {
    background: $color-white;
    border-radius: 8px;
    font-size: 1rem;
    margin: 0 1rem 1rem 1rem;
    opacity: 0;
    padding: 1rem;
    transition: opacity 0.25s ease-in-out;
    transition-delay: 0.25s;
  }

  &__logo-wrapper {
    display: flex;
    height: 5rem;
    width: 5rem;
    border: 3px white solid;
    border-radius: 3rem;
    margin-right: 1rem;
  }

  &__header {
    margin-right: 2rem;
  }

  &__heading {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    line-height: 1;
  }

  &__subheading {
    font-size: 1.125rem;
    color: $color-primary;
    margin-bottom: 0.25rem;
    line-height: 1;
  }

  &__description {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }

  &__logo {
    color: $color-secondary;
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
    transition: color 0.25s ease-in-out;
    flex: 0 0 auto;
  }

  &__icon {
    flex: 0 0 auto;
    color: $color-primary;
    height: 1.75rem;
    width: 1.75rem;
    transition: color 0.25s ease-in-out, transform 0.25s ease-in-out;
    margin-left: auto;
  }

  &__status {
    margin-left: auto;
    pointer-events: none;
  }

  &__button {
    border-radius: 12px;
    height: 56px;
    line-height: 56px;
  }

  &--primary {
    .accordion-block__logo,
    .accordion-block__icon {
      color: $color-white;
    }

    .accordion-block__main {
      background-color: $color-primary;
      color: $color-white;

      &:hover {
        background-color: darken($color-primary, 5%);
      }
    }

    .accordion-block__sub {
      background-color: $color-primary;
    }

    &.is-completed {
      .accordion-block__main {
        background-color: $color-white;
        color: $color-text;
      }

      .accordion-block__sub {
        background-color: $color-white;
      }

      .accordion-block__logo {
        color: $color-text;
      }

      .accordion-block__icon {
        color: $color-primary;
      }
    }
  }

  &:not(.is-completed) {
    .accordion-block__main {
      &:hover {
        .accordion-block__icon {
          transform: translateY(2px);
        }
      }
    }

    &.is-open {
      .accordion-block__main {
        &:hover {
          .accordion-block__icon {
            transform: translateY(2px) rotate(-180deg);
          }
        }
      }

      .accordion-block__icon {
        transform: rotate(-180deg);
      }
    }
  }

  &.is-open {
    .accordion-block {
      &__logo,
      &__icon {
        color: $color-white;
      }

      &__main {
        background-color: $color-primary;
        color: $color-white;
      }

      &__sub {
        background-color: $color-primary;
        max-height: 9999px;
      }

      &__content {
        opacity: 1;
      }

      &__button {
        visibility: hidden;
      }
    }
  }

  @media screen and (min-width: $screen-size-m) {
    &__main {
      padding: 2rem;
    }

    &__content {
      margin: 0 2rem 2rem 2rem;
      padding: 2rem;
    }

    &__heading {
      font-size: 1.75rem;
    }

    &__subtitle {
      font-size: 1.5rem;
    }

    &__description {
      font-size: 0.875rem;
    }

    &__logo {
      height: 4rem;
      width: 4rem;
    }

    &__icon {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
</style>
