<script>
import { computed, ref, watch, toRefs } from 'vue';
import moment from 'moment';
import Button from '../../Button.vue';
import TokensBanner from '../TokensBanner';
import TokensForm from './TokensPurchaseForm';
import TokensResult from './TokensPurchaseResult';
import InfoButton from '../../InfoButton.vue';
import { appConfig } from '../../../config';
import { useState } from '../../../composables/useState';
import { useFetch } from '../../../composables/useFetch';

export default {
  components: {
    Button,
    TokensForm,
    TokensResult,
    TokensBanner,
    InfoButton,
  },
  props: {
    tokens: Array,
    walletAddress: String,
    rounds: Array,
    statusPerRound: Object,
    maxTokensPerPerson: Number,
    maxTokensPerPersonFcnft: Number,
    hasFcnft: {
      type: Boolean,
      default: false,
    },
    isTokensActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const options = { ...appConfig };
    const { state, setState } = useState();
    const { fetchData } = useFetch();
    const isFormActive = ref(false);
    const isFormCompleted = ref(false);

    setState({
      tokensRequestLoading: false,
      tokensRequestError: null,
      tokensRequestCompleted: false,

      tokensEditLoading: false,
      tokensEditError: null,
      tokensEditCompleted: false,

      tokensDeleteLoading: false,
      tokensDeleteError: null,
      tokensDeleteCompleted: false,

      tokensPurchaseLoading: false,
      tokensPurchaseError: null,
      tokensPurchaseCompleted: false,
    });

    const handleSubmitTokensRequest = async event => {
      event.preventDefault();

      setState({
        tokensRequestCompleted: false,
        tokensRequestLoading: false,
        tokensRequestError: null,
      });

      const { purchaser_id: purchaserId, session_id: sessionId, tokens } = state;
      const form = event.target;
      const formData = new FormData(form);

      // convert to JSON
      const formDataObject = Array.from(formData.keys()).reduce((result, key) => {
        result[key] = formData.get(key);

        return result;
      }, {});

      const { result, error, loading } = fetchData({
        url: `${options.api.purchaser}/${purchaserId}/tokens`,
        method: 'POST',
        body: JSON.stringify(formDataObject),
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          'Content-Type': 'application/json',
        },
      });

      setState({
        tokensRequestLoading: loading,
        tokensRequestError: error,
      });

      const data = await result.value;

      if (data) {
        const newTokens = [...tokens, data];

        setState({
          tokens: newTokens,
          tokensRequestCompleted: true,
        });
      }
    };

    const handleEditTokensRequest = async ({ event, tokenId }) => {
      event.preventDefault();

      setState({
        tokensEditCompleted: false,
        tokensEditLoading: false,
        tokensEditError: null,
      });

      const { purchaser_id: purchaserId, session_id: sessionId, tokens } = state;
      const form = event.target;
      const formData = new FormData(form);

      // convert to JSON
      const formDataObject = Array.from(formData.keys()).reduce((result, key) => {
        result[key] = formData.get(key);

        return result;
      }, {});

      const { result, error, loading } = fetchData({
        url: `${options.api.tokens}/${tokenId}`,
        method: 'PATCH',
        body: JSON.stringify(formDataObject),
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          key: purchaserId,
          'Content-Type': 'application/json',
        },
      });

      setState({
        tokensEditLoading: loading,
        tokensEditError: error,
      });

      const data = await result.value;

      if (data) {
        const newTokens = [...tokens];
        const tokenIndex = newTokens.findIndex(token => token._id === tokenId);
        newTokens[tokenIndex] = data;

        setState({
          tokens: newTokens,
          tokensEditCompleted: true,
        });
      }
    };

    const handleDeleteTokensRequest = async ({ event, tokenId }) => {
      event.preventDefault();

      setState({
        tokensDeleteCompleted: false,
        tokensDeleteLoading: false,
        tokensDeleteError: null,
      });

      const { purchaser_id: purchaserId, session_id: sessionId, tokens } = state;

      const { result, error, loading } = fetchData({
        url: `${options.api.tokens}/${tokenId}`,
        method: 'PATCH',
        body: JSON.stringify({
          quantity: 0,
        }),
        headers: {
          Authorization: sessionId,
          apikey: options.tokens.restDb,
          key: purchaserId,
          'Content-Type': 'application/json',
        },
      });

      setState({
        tokenDeleteLoading: loading,
        tokenDeleteError: error,
      });

      const data = await result.value;

      if (data) {
        const newTokens = [...tokens];
        const tokenIndex = newTokens.findIndex(token => token._id === tokenId);
        newTokens[tokenIndex] = data;

        setState({
          tokens: newTokens,
          tokensDeleteCompleted: true,
        });
      }
    };

    const toggleFormActive = event => {
      event.preventDefault();

      // Toggle value
      isFormActive.value = !isFormActive.value;
      isFormCompleted.value = false;
    };

    const getStatus = (quantity, adaAmount, purchaseWindowStarttime, purchaseWindowEndtime, transactionId) => {
      const currentTime = moment().utc().valueOf();
      const endTime = moment.utc(purchaseWindowEndtime).valueOf();

      const isComplete = quantity && purchaseWindowStarttime && adaAmount && transactionId;
      const isActive = quantity && purchaseWindowStarttime && adaAmount && !transactionId;
      const isIncomplete = quantity && purchaseWindowStarttime && !adaAmount && !transactionId;
      const isSubmitted = quantity && !purchaseWindowStarttime && !adaAmount && !transactionId;
      const isExpired = purchaseWindowEndtime ? currentTime > endTime : false;

      if (isSubmitted) {
        return 'Submitted';
      }

      if (isComplete) {
        return 'Complete';
      }

      if (isExpired || isIncomplete) {
        return 'Incomplete';
      }

      if (isActive) {
        return 'Active';
      }

      return null;
    };

    const getPurchaseWindowEndtime = purchaseWindowStarttime => {
      if (purchaseWindowStarttime) {
        return moment.utc(purchaseWindowStarttime).add(1, 'days');
      }

      return null;
    };

    const showInstructionsModal = () => {
      setState({ showInstructions: true });
    };

    const tokensWithStatus = computed(() => {
      return props.tokens
        .filter(item => item.quantity > 0)
        .map(item => {
          const {
            quantity = null,
            ada_amount: adaAmount = null,
            purchase_window_starttime: purchaseWindowStarttime = null,
            transaction_id: transactionId = null,
          } = item;

          const purchaseWindowEndtime = getPurchaseWindowEndtime(purchaseWindowStarttime);
          const status = getStatus(quantity, adaAmount, purchaseWindowStarttime, purchaseWindowEndtime, transactionId);

          return {
            ...item,
            status,
            purchase_window_endtime: purchaseWindowEndtime,
          };
        });
    });

    const totalTokens = computed(() => {
      const total =
        tokensWithStatus.value.filter(token => token.status === 'Complete').reduce((a, b) => a + b.quantity, 0) || 0;

      setState({ tokensPurchasedTotal: total });

      return total;
    });

    const totalNonIncompleteTokens = computed(
      () =>
        tokensWithStatus.value
          .filter(token => ['Complete', 'Active', 'Submitted'].includes(token.status))
          .reduce((a, b) => a + b.quantity, 0) || 0,
    );

    const availableRounds = computed(() => {
      const rounds = props.rounds
        .filter(round => !round.endDate || moment.utc(round.endDate).valueOf() > moment().utc().valueOf())
        .map(round => {
          const tokensAvailablePerRound =
            tokensWithStatus.value
              .filter(token => token.round === round.roundNr && token.status && token.status !== 'Incomplete')
              .reduce((a, b) => a + b.quantity, 0) || 0;

          return {
            ...round,
            min: tokensAvailablePerRound ? 1 : round.min,
            // sets the max amount allowed for FC members to minimum of
            //    round_maximum - (round_complete + round_submitted + round_active) and 6000 - (total_complete + total_submitted + total_active)
            max: Math.min(
              round.max - tokensAvailablePerRound,
              props.maxTokensPerPerson - totalNonIncompleteTokens.value,
            ),
          };
        });

      if (props.hasFcnft) {
        // sets the max amount allowed for FC members to 6000 - (total_complete + total_submitted + total_active)
        if (rounds.length > 0) {
          return [{ ...rounds[0], max: props.maxTokensPerPersonFcnft - totalNonIncompleteTokens.value }];
        }

        return [];
      }

      return rounds.filter(round => round.roundNr !== 0);
    });

    const currentRound = computed(() => {
      return availableRounds.value[0];
    });

    const currentRoundNr = computed(() => {
      if (
        !currentRound.value?.startDate ||
        moment.utc(currentRound.value.startDate).valueOf() > moment().utc().valueOf()
      ) {
        return 'Not started';
      }

      return currentRound.value?.label;
    });

    const nextRound = computed(() =>
      availableRounds.value.find(round => moment.utc(round.startDate).valueOf() > moment().utc().valueOf()),
    );

    const nextRoundDate = computed(() => {
      if (nextRound.value?.startDate) {
        return moment.utc(nextRound.value.startDate).local();
      }

      return 'End of rounds';
    });

    watch(
      () => state.tokensRequestCompleted,
      value => {
        if (value) {
          isFormActive.value = false;
          isFormCompleted.value = true;
        }
      },
    );

    return {
      // refs
      ...toRefs(state),
      isFormActive,
      isFormCompleted,
      tokensWithStatus,
      totalTokens,
      availableRounds,
      currentRound,
      currentRoundNr,
      nextRound,
      nextRoundDate,

      // methods
      toggleFormActive,
      showInstructionsModal,

      // handlers
      handleSubmitTokensRequest,
      handleEditTokensRequest,
      handleDeleteTokensRequest,
    };
  },
};
</script>

<template>
  <div class="tokens-purchase">
    <template v-if="isTokensActive && availableRounds?.length > 0">
      <div class="tokens-purchase__header">
        <TokensBanner v-if="totalTokens" title="Total Purchased EMP" :amount="totalTokens" />

        <!-- Rounds text -->
        <template v-if="!hasFcnft">
          <p v-if="currentRoundNr">
            Current round: <br />
            <strong>
              {{ currentRoundNr }}
            </strong>
          </p>
          <p v-if="nextRoundDate">
            Next round starts on: <br /><strong>{{ nextRoundDate }}</strong>
          </p>

          <hr />
        </template>

        <div class="tokens-purchase__group">
          <div style="display: flex; align-items: center">
            <h3 class="tokens-purchase__heading">
              <span v-if="hasFcnft">Token Purchases</span>
              <span v-else>Token Requests</span>
            </h3>
            <!-- <InstructionsButton class="tokens-purchase__info" /> -->
            <InfoButton
              class="tokens-purchase__info"
              tooltipText="Click to see the instructions"
              @click="showInstructionsModal()"
            />
          </div>
          <Button v-if="!isFormActive" variant="secondary" class="tokens-purchase__add" @click="toggleFormActive">
            <span v-if="hasFcnft">Purchase tokens</span> <span v-else>Add new request</span>
          </Button>
        </div>
        <p v-if="!hasFcnft">Submit requests for any rounds still available</p>
      </div>
      <div class="tokens-purchase__content">
        <TokensForm
          v-if="isFormActive"
          class="tokens-purchase__form"
          :current-round="currentRound"
          :can-select-round="!hasFcnft"
          :has-fcnft="hasFcnft"
          :rounds="availableRounds"
          :error="tokensRequestError"
          :loading="tokensRequestLoading"
          @submit="handleSubmitTokensRequest"
          @cancel-form="toggleFormActive"
        />

        <p v-if="isFormCompleted">Your request has been added below.</p>

        <TokensResult
          v-if="tokensWithStatus?.length > 0"
          class="tokens-purchase__items"
          @edit-request="handleEditTokensRequest"
          @delete-request="handleDeleteTokensRequest"
          :has-fcnft="hasFcnft"
          :status="{
            error: tokensEditError,
            loading: tokensEditLoading,
            completed: tokensEditCompleted,
          }"
          :items="tokensWithStatus"
          :rounds="rounds"
          :walletAddress="walletAddress"
        />
        <p v-else>No requests have been submitted yet.</p>

        <hr />
      </div>
    </template>
    <template v-else-if="availableRounds?.length === 0">
      <div class="tokens-purchase__header">
        <p>
          You can start claiming your tokens on the claim screen. Just switch to
          <strong style="color: #f9792f">claimed ↑</strong>.
        </p>
        <hr />
        <h3>Overview</h3>
        <TokensBanner v-if="totalTokens" title="Total Purchased EMP" :amount="totalTokens" />
      </div>
      <div class="tokens-purchase__content">
        <h4>Transaction History</h4>
        <TokensResult
          v-if="tokensWithStatus?.length > 0"
          class="tokens-purchase__items"
          @edit-request="handleEditTokensRequest"
          @delete-request="handleDeleteTokensRequest"
          :has-fcnft="hasFcnft"
          :status="{
            error: tokensEditError,
            loading: tokensEditLoading,
            completed: tokensEditCompleted,
          }"
          :items="tokensWithStatus"
          :rounds="rounds"
          :walletAddress="walletAddress"
        />
      </div>
    </template>
    <template v-else>
      <p v-if="hasFcnft">
        As a Founding Community NFT holder this section will be used to perform token purchases once the token sale
        commences.
      </p>
      <p v-else>
        This section will show your token allocation requests and successful purchases for each token sale round.
      </p>
      <p>
        <strong>
          This feature is currently disabled for your account. <br />
          For further assistance please contact our support team.
        </strong>
      </p>

      <hr />
    </template>
  </div>
</template>

<style lang="scss">
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.tokens-purchase {
  &__header {
    margin-bottom: 2rem;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  &__info {
    margin-left: 0.5rem;
    display: inline-block;
    position: relative;
    top: -5px;
  }

  @media screen and (min-width: 768px) {
    &__heading {
      margin: 0;
    }

    &__group {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &__info {
      top: 3px;
    }
  }
}
</style>
