<script>
import ArrowDownIcon from './icons/ArrowDownIcon';
import CheckIcon from './icons/CheckIcon';

export default {
  name: 'Accordion',
  components: {
    ArrowDownIcon,
    CheckIcon,
  },
  props: {
    variant: {
      type: String,
      value: ['primary', 'secondary'],
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAccordion: false,
    };
  },
  computed: {
    modiferClasses() {
      const { variant, disabled } = this;

      if (variant) {
        return `accordion--${variant}`;
      }

      if (disabled) {
        return `accordion--disabled`;
      }

      return false;
    },
  },
  methods: {
    toggleAccordion() {
      this.showAccordion = !this.showAccordion;
    },
  },
  setup(props, { slots }) {
    const titleSlot = slots.title;
    const subtitleSlot = slots.subtitle;
    const statusSlot = slots.status;
    const contentSlot = slots.default;
    const descriptionSlot = slots.description;

    return {
      titleSlot,
      subtitleSlot,
      statusSlot,
      descriptionSlot,
      contentSlot,
    };
  },
};
</script>

<template>
  <div
    class="accordion"
    :class="[
      modiferClasses,
      { 'is-open': showAccordion || isActive, 'is-completed': isCompleted, 'is-disabled': disabled },
    ]"
  >
    <div class="accordion__main" @click="toggleAccordion">
      <header class="accordion__header">
        <h2 class="accordion__heading" v-if="titleSlot">
          <div>
            <span class="accordion__title"><slot name="title" /></span>
            <span v-if="subtitleSlot" class="accordion__subtitle"><slot name="subtitle" /></span>
          </div>
          <span v-if="statusSlot" class="accordion__status"><slot name="status" /></span>
        </h2>
      </header>
      <CheckIcon v-if="isCompleted" class="accordion__icon" />
      <ArrowDownIcon v-else class="accordion__icon" />
    </div>
    <div class="accordion__sub">
      <div class="accordion__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.accordion {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 23px rgba(0, 0, 0, 0.06), 0 28px 92px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  width: 100%;

  &__main {
    align-items: center;
    background-color: $color-white;
    cursor: pointer;
    display: flex;
    padding: 0.75rem;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

    &:hover {
      background-color: darken($color-white, 3%);
    }
  }

  &__sub {
    background-color: $color-white;
    max-height: 0;
    overflow: hidden;
    transition: background-color 0.25s ease-in-out, max-height 0.5s ease-in-out;
  }

  &__content {
    background: $color-white;
    border-radius: 8px;
    font-size: 1rem;
    margin: 0 0.75rem 0.75rem 0.75rem;
    opacity: 0;
    padding: 0.75rem;
    transition: opacity 0.25s ease-in-out;
    transition-delay: 0.25s;
  }

  &__header {
    margin-right: 2rem;
    width: 100%;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    line-height: 1;
  }

  &__title,
  &__subtitle {
    display: block;
  }

  &__icon {
    flex: 0 0 auto;
    color: $color-primary;
    height: 1.75rem;
    width: 1.75rem;
    transition: color 0.25s ease-in-out, transform 0.25s ease-in-out;
    margin-left: auto;
  }

  &__button {
    border-radius: 12px;
    height: 56px;
    line-height: 56px;
  }

  &:not(.is-completed) {
    .accordion__main {
      &:hover {
        .accordion__icon {
          transform: translateY(2px);
        }
      }
    }

    &.is-open {
      .accordion__main {
        &:hover {
          .accordion__icon {
            transform: translateY(2px) rotate(-180deg);
          }
        }
      }

      .accordion__icon {
        transform: rotate(-180deg);
      }
    }
  }

  &.is-disabled {
    pointer-events: none;
  }

  &.is-open {
    .accordion {
      &__icon {
        color: $color-white;
      }

      &__main {
        background-color: $color-primary;
        color: $color-white;
      }

      &__sub {
        background-color: $color-primary;
        max-height: 2000px;
      }

      &__content {
        opacity: 1;
      }

      &__button {
        visibility: hidden;
      }
    }
  }

  @media screen and (min-width: $screen-size-m) {
    &__main {
      padding: 1rem;
    }

    &__content {
      margin: 0 1rem 1rem 1rem;
      padding: 1rem;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.25rem;
    }

    &__title,
    &__subtitle {
      display: inline-block;
    }

    &__subtitle {
      margin-left: 3rem;
    }

    &__status {
      margin-left: auto;
    }

    &__icon {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
}
</style>
