<script>
import { onMounted, ref } from 'vue';
import tippy from 'tippy.js';
import copyIcon from '../assets/icons/copy.svg';

export default {
  props: {
    text: {
      type: [String, Number],
    },
  },
  setup(props) {
    const trigger = ref(null);

    const init = () => {
      tippy(trigger.value, {
        theme: 'light',
        trigger: 'click',
        hideOnClick: true,
        maxWidth: 'none',
        placement: 'top',
        allowHTML: true,
        interactive: true,
        animation: 'shift-away',
        zIndex: 1,
        duration: [700, 100],
        async onTrigger(instance) {
          await navigator.clipboard.writeText(props.text);
          instance.setContent('Copied to clipboard!');
        },
      });
    };

    onMounted(() => {
      init();
    });

    return {
      copyIcon,
      trigger,
    };
  },
};
</script>

<template>
  <div class="copy">
    <span class="copy__text">{{ text }}</span>
    <div class="copy__button" ref="trigger">
      <img :src="copyIcon" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables';

.copy {
  display: flex;
  border: solid 1px $color-primary;
  border-radius: 4px;

  &__text {
    display: block;
    word-break: break-all;
    padding: 0.5rem;
    font-size: 0.875rem;
    flex: 1;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
  }
}
</style>
