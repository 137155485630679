<script>
import { ref, watch } from 'vue';
import Accordion from '../../Accordion.vue';
import TokensForm from './TokensPurchaseForm.vue';
import Button from '../../Button.vue';
import QrCode from '../../QrCode.vue';
import { useCountdown } from '../../../composables/useCountDown';

export default {
  components: {
    Accordion,
    TokensForm,
    Button,
    QrCode,
  },
  props: {
    items: Array,
    rounds: Array,
    status: Object,
    walletAddress: String,
    hasFcnft: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { initCountdownTimer } = useCountdown();
    const isFormActive = ref(false);
    const isFormCompleted = ref(false);
    const hasAgreedToTerms = ref(false);

    const toggleFormActive = (event, id) => {
      event.preventDefault();

      isFormCompleted.value = false;

      if (isFormActive.value === id) {
        isFormActive.value = null;
      } else {
        isFormActive.value = id;
      }
    };

    const toggleAgreement = event => {
      event.preventDefault();

      // Toggle value
      hasAgreedToTerms.value = !hasAgreedToTerms.value;
    };

    const handleDeleteRequest = (event, tokenId) => {
      const warningText = props.hasFcnft
        ? 'Are you sure you want to continue?'
        : 'Deleting your request will remove it from the queue, losing your position. Are you sure you want to continue?';

      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm(warningText) === true) {
        emit('delete-request', { event, tokenId });
      }

      return false;
    };

    const handleEditRequest = (event, tokenId) => {
      emit('edit-request', { event, tokenId });
    };

    const startCountdown = endTime => {
      return initCountdownTimer(endTime);
    };

    const modifyCurrentRound = currentItem => {
      const currentRound = props.rounds.find(round => round.roundNr === currentItem.round);
      const itemsTotal = props.items
        .filter(item => item.round === currentItem.round && item._id !== currentItem._id)
        .reduce((a, b) => a + b.quantity, 0);

      return {
        ...currentRound,
        max: currentRound.max - itemsTotal,
      };
    };

    watch(
      () => props.status.completed,
      value => {
        if (value) {
          isFormCompleted.value = value;
          isFormActive.value = false;
        }
      },
      { immediate: true },
    );

    return {
      isFormActive,
      isFormCompleted,
      hasAgreedToTerms,

      startCountdown,
      toggleFormActive,
      toggleAgreement,
      handleDeleteRequest,
      handleEditRequest,
      modifyCurrentRound,
    };
  },
};
</script>

<template>
  <div class="tokens-result">
    <ul class="tokens-result__list">
      <li v-for="item in items" :key="item._id" class="tokens-result__item">
        <Accordion :is-completed="item.status === 'Completed'" :is-active="item.status === 'Active'">
          <template v-slot:title>
            <span v-if="item.round === 0">Founding Community</span>
            <span v-else>Round {{ item.round }}</span>
          </template>
          <template v-slot:subtitle>
            <span class="tokens-result__quantity">{{ item.quantity }} EMP</span>
          </template>
          <template v-slot:status>
            <span>{{ item.status }}</span>
          </template>
          <template v-slot:content>
            <template v-if="item.status === 'Submitted'">
              <template v-if="hasFcnft">
                <p>Please wait while the amount of ADA required for your purchase is calculated</p>
              </template>
              <template v-else>
                <p><strong>This round has not started yet.</strong></p>

                <p>
                  Your request has been placed in the queue and you will be notified if it is able to be processed once
                  the round commences.
                </p>
              </template>

              <p v-if="isFormCompleted">
                You have successfully edited the token amount to <strong>{{ item.quantity }}</strong>
              </p>

              <div class="tokens-result__group">
                <Button
                  class="tokens-result__button"
                  v-if="isFormActive !== item._id"
                  @click="toggleFormActive($event, item._id)"
                >
                  Edit amount
                </Button>

                <a
                  v-if="isFormActive !== item._id"
                  href="#"
                  class="tokens-result__delete"
                  @click="handleDeleteRequest($event, item._id)"
                >
                  Delete Request
                </a>
              </div>

              <div class="tokens-result__form" v-if="isFormActive === item._id">
                <h3>Edit your tokens request</h3>
                <TokensForm
                  v-if="isFormActive === item._id"
                  :current-round="modifyCurrentRound(item)"
                  :current-token="item"
                  :can-select-round="false"
                  :rounds="rounds"
                  :loading="status.loading"
                  :error="status.error"
                  @submit="handleEditRequest($event, item._id)"
                  @cancel-form="toggleFormActive($event, item._id)"
                  @delete-request="handleDeleteRequest($event, item._id)"
                />
              </div>
            </template>
            <template v-else-if="item.status === 'Active'">
              <p v-if="!hasFcnft"><strong>Congratulations! Your request has reached the top of the queue.</strong></p>

              <template v-if="!hasAgreedToTerms">
                <p>
                  I agree that I will not transfer my payment from an exchange account or unsupported wallet. I also
                  agree to use the same wallet for all payments made during the token sale.
                </p>

                <p>
                  I understand that if I do not follow these conditions, that my purchase will not be fulfilled and any
                  ADA I transfer may be lost.
                </p>

                <Button @click="toggleAgreement">I understand & agree</Button>
              </template>
              <template v-else>
                <p>
                  To purchase your EMP tokens please transfer exactly <strong>{{ item.ada_amount }} ADA</strong> to the
                  payment address below, prior to your purchase window expiring.
                </p>
                <p style="background-color: #410000; padding: 1rem; color: #fff; border-radius: 5px">
                  <strong>⚠️ WARNING<br /></strong> Only payments from the same wallet as previously used with the Token
                  Sale Dashboard (e.g. in a previous round or for Founding Community wallet linking) will be accepted.
                  Payments made from a different wallet will not show in your Dashboard.
                </p>
                <QrCode :url="walletAddress" label="Payment Address" style="margin-bottom: 1rem" />
                <p>
                  Your payment window expires in: <strong>{{ startCountdown(item.purchase_window_endtime) }}</strong>
                </p>
                <p>Once your payment has been received, the status of your purchase will be updated.</p>
                <p>
                  <strong>
                    Note: Transactions may take several minutes to process. Follow the transaction progress on
                    cardanoscan.io using the payment address.
                  </strong>
                </p>
              </template>
            </template>
            <template v-else-if="item.status === 'Incomplete'">
              <p><strong>Your purchase has not been completed.</strong></p>

              <p>
                Unfortunately we did not receive your payment within your purchase window. Please do NOT send any funds
                now for this purchase, as they will be returned.
              </p>

              <p>To restart your purchase please submit a new request amount.</p>

              <p v-if="hasFcnft">
                NOTE: Your new request will have a different position in the queue and therefore may not be able to be
                fulfilled.
              </p>
            </template>
            <template v-else-if="item.status === 'Complete'">
              <p><strong>Thank you for completing your purchase.</strong></p>
              <p>
                You have successfully secured <strong>{{ item.quantity }} EMP</strong> tokens that will be made
                available to you following the Token Generation Event.
              </p>
              <p v-if="item.comment">
                <strong>NOTE: {{ item.comment }}</strong>
              </p>
            </template>
            <template v-else>
              <p>We can't determine the status of your request or purchase. Please contact us at info@empowa.io.</p>
            </template>
          </template>
        </Accordion>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.tokens-result {
  @import '../../../assets/scss/variables';
  @import '../../../assets/scss/mixins';

  &__list {
    @include list-reset;
  }

  &__item {
    margin-bottom: 1rem;
  }

  &__delete {
    display: inline-block;
    margin-top: 0.5rem;
    float: right;
  }

  &__button {
    margin-right: 1rem;
  }

  &__group {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  @media screen and (min-width: 768px) {
    &__quantity {
      text-align: right;
      width: 130px;
      display: inline-block;
    }
  }
}
</style>
