<script>
import { ref, watch } from 'vue';
import { useState } from '../../../composables/useState';
import Button from '../../Button.vue';
import Modal from '../../Modal.vue';

export default {
  name: 'InstructionsModal',
  components: {
    Button,
    Modal,
  },
  setup() {
    const { state, setState } = useState();
    const showTooltip = ref(false);
    const showModal = ref(state.showInstructions);
    const isFC = ref(false);

    const closeInstructionsModal = () => {
      setState({ showInstructions: false });
    };

    watch([() => state.showInstructions], ([show]) => {
      showModal.value = show;
      isFC.value = state.fcnft !== '' && state.fcnft !== null;
    });

    return {
      showTooltip,
      showModal,
      isFC,
      closeInstructionsModal,
    };
  },
};
</script>

<template>
  <div>
    <Modal :zIndex="100" :closable="true" @press-close="closeInstructionsModal()" :show="showModal">
      <template v-slot:content>
        <div class="instructions-modal">
          <h4 class="instructions-modal__title">Instructions</h4>
          <div class="instructions-modal__description">
            <p v-if="!isFC">
              To reserve your place in the token sale queue, please specify the number of tokens you wish to purchase in
              each round. If you do not wish to participate in all rounds, then only submit requests for the rounds you
              do. <br /><br />Multiple requests per round are permitted up to the maximum individual allocations per
              round. This will allow people who do not purchase their maximum allocation with their first request, to
              make another purchase during the same round if tokens are still available. <strong>NOTE:</strong> Each
              request will have its own place in the purchase queue based on the day and time it is submitted.
              <br /><br />To submit a token purchase request click the “Add new request” button and enter the amount of
              tokens you wish to purchase for the specified round. Once the round has commenced and your place in the
              queue has been reached, the token request tile will display the amount of ADA you need to transfer to
              complete your purchase. You will have 24 hours to perform the transaction using the payment address
              displayed. <br /><br /><strong>IMPORTANT:</strong> Always use the same wallet for each token purchase and
              do NOT use an exchange account. The wallet used to make your first token purchase will be used to link
              your wallet to the Token Sale Dashboard and will be used to claim EMP tokens following the token
              generation event. Subsequent payments not made from this linked wallet will be returned and your token
              purchase will not be fulfilled. <br /><br />Payments made from an exchange account will be returned and
              your token purchase will not be fulfilled. As many exchanges use different wallets for sending and
              receiving, returned funds may not be credited to your account and may be lost. Empowa are unable to assist
              in the recovery of funds sent from an exchange account.
            </p>
            <p v-else>
              As a Founding Community NFT holder you are eligible to purchase tokens at any time during the token sale
              prior to the end of Round 3 and up to the maximum individual allocation of 6000 EMP. You do not have to
              purchase all your tokens in one transaction. <br /><br />To make a purchase click the “Purchase Tokens”
              button and enter the amount of tokens you wish to buy. Once the Dashboard has calculated the amount of ADA
              you need to transfer, you will have 24 hours to perform the transaction using the payment address
              displayed. <br /><br /><strong>NOTE:</strong> You must send ADA payments from your linked wallet and your
              FC NFT must remain in your wallet for the full duration of the token sale. Payments made from a different
              wallet will be returned and your token purchase will not be fulfilled.
            </p>
            <Button @click="closeInstructionsModal">I understand</Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.instructions-modal {
  &__title {
    line-height: 2rem;
    color: $color-primary;
  }

  &__description {
    max-height: 600px;
    overflow-y: auto;
    padding-right: 2rem;

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
</style>
