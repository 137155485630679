<template>
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 28.3335C19.1567 28.3335 18.35 27.9452 17.79 27.2635L10.7683 18.7668C9.92832 17.7485 9.75998 16.3035 10.3367 15.0818C10.845 14.0035 11.8567 13.3335 12.9783 13.3335H27.0217C28.1433 13.3335 29.155 14.0035 29.6633 15.0818C30.24 16.3035 30.0717 17.7485 29.2333 18.7652L22.21 27.2635C21.65 27.9452 20.8433 28.3335 20 28.3335Z"
      fill="currentColor"
    />
  </svg>
</template>
