<script>
import { computed } from 'vue';
import EmpowaTokenIcon from '../icons/EmpowaTokenIcon';

export default {
  components: {
    EmpowaTokenIcon,
  },
  props: {
    title: String,
    amount: Number,
    subamount: Number,
  },
  setup(props) {
    const formattedAmount = computed(() => {
      return props.amount.toLocaleString('us-Us');
    });

    const formattedSubamount = computed(() => {
      if (props.subamount || props.subamount === 0) {
        return props.subamount.toLocaleString('us-Us');
      }

      return null;
    });

    return {
      formattedAmount,
      formattedSubamount,
    };
  },
};
</script>

<template>
  <article class="token-banner">
    <EmpowaTokenIcon class="token-banner__icon" />
    <div>
      <h2 class="token-banner__heading">{{ title }}</h2>
      <p class="token-banner__amount">
        {{ formattedAmount }} <template v-if="formattedSubamount">of {{ formattedSubamount }}</template>
      </p>
    </div>
  </article>
</template>

<style lang="scss">
@import '../../assets/scss/variables';

.token-banner {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  border-radius: 5px;
  color: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar-thumb {
    background-color: lighten($color-secondary, 20%);
  }

  .token-banner__heading {
    font-size: 1rem;
    margin: 0;
  }

  .token-banner__amount {
    font-size: 2rem;
  }

  .token-banner__icon {
    height: 64px;
    line-height: 1.2;
    margin-right: 1rem;
    width: 64px;
  }
}
</style>
