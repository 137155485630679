<script>
import { toRefs, computed } from 'vue';
import CloseIcon from './icons/CloseIcon.vue';

export default {
  name: 'Modal',
  props: {
    show: Boolean,
    width: {
      type: Number,
      required: false,
      default: 714,
    },
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['press-close'],
  components: {
    CloseIcon,
  },
  setup(props, { emit }) {
    const { show, width, closable } = toRefs(props);
    const modalStyle = computed(() => {
      return `width: ${width.value}px;`;
    });

    const closeModal = () => {
      emit('press-close', {});
    };

    return {
      // eslint-disable-next-line vue/no-dupe-keys
      show,
      // eslint-disable-next-line vue/no-dupe-keys
      width,
      // eslint-disable-next-line vue/no-dupe-keys
      closable,

      modalStyle,
      closeModal,
    };
  },
};
</script>

<template>
  <div v-if="show" class="modal">
    <div :style="modalStyle" class="modal__content">
      <span class="modal__close" @click="closeModal()">
        <CloseIcon />
      </span>
      <slot name="content" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../assets/scss/variables';
@import '../assets/scss/mixins';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;

  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 40px;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    z-index: 150;
  }

  &__close {
    float: right;
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    &__content {
      width: 90%;
      max-width: 100%;
    }
  }
}
</style>
