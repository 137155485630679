import { readonly, reactive } from 'vue';

// Global state
const state = reactive({});

export const useState = () => {
  const setState = newState => {
    Object.assign(state, {
      ...state,
      ...newState,
    });
  };

  return { state: readonly(state), setState };
};
