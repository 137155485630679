<script>
import { ref, readonly, watch } from 'vue';
import Modal from '../../Modal.vue';
import Button from '../../Button.vue';
import { useFetch } from '../../../composables/useFetch';
import { useState } from '../../../composables/useState';
import { appConfig as config } from '../../../config';

export default {
  name: 'SurveyModal',
  components: {
    Button,
    Modal,
  },
  setup() {
    const { state, setState } = useState();
    const { fetchData } = useFetch();
    const showModal = ref(state.showSurvey);
    const surveySubmitted = ref(false);
    const minQuantity = readonly(0);
    const quantity = ref(0);

    const closeSurveyModal = () => {
      setState({ showSurvey: false });
    };

    const handleSurveySubmission = async ev => {
      ev.preventDefault();

      // eslint-disable-next-line radix
      const payload = { round4_survey: parseInt(ev.target.elements.quantity.value) };

      const { result } = fetchData({
        url: `${config.api.purchaser}/${state.purchaser_id}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          apikey: config.tokens.restDb,
          Authorization: state.session_id,
          'Content-Type': 'application/json',
        },
      });

      const data = await result.value;

      if (data) {
        setState({ round4_survey: data.round4_survey });
      }

      surveySubmitted.value = true;

      setTimeout(() => {
        setState({ showSurvey: false });
        surveySubmitted.value = false;
      }, 3000);
    };

    watch([() => state.showSurvey], ([show]) => {
      showModal.value = show;
      quantity.value = state.round4_survey;
    });

    return {
      // refs
      showModal,
      surveySubmitted,
      minQuantity,
      quantity,

      // methods
      closeSurveyModal,
      handleSurveySubmission,
    };
  },
};
</script>

<template>
  <div>
    <Modal :zIndex="100" :closable="true" @press-close="closeSurveyModal()" :show="showModal">
      <template v-slot:content>
        <div class="survey-modal">
          <h4 class="survey-modal__title">Round 4 Survey</h4>
          <div v-if="!surveySubmitted">
            <div class="survey-modal__description">
              <p>
                If the token allocation for the Public Sale (20 million EMP) does not sell out during Rounds 1 to 3, a
                fourth round will be held, where participants will be given the opportunity to purchase additional
                tokens. Individual allocation limits for Round 4 will be dynamically determined based on the number of
                tokens remaining and the amount of additional tokens people indicate they would like to purchase.
              </p>
              <p>
                NOTE: This is a survey only and will not be considered an official purchase request for Round 4. If
                Round 4 is conducted, you will be given the opportunity to submit a purchase request beforehand. You can
                also change your survey response later if you choose, by clicking on the survey icon below.
              </p>
              <p>
                <strong>
                  If Round 4 is held, how many additional EMP tokens would you like to purchase (enter 0 if you do not
                  wish to participate in Round 4)?
                </strong>
              </p>
            </div>
            <form class="survey-modal__form" @submit="handleSurveySubmission">
              <div class="survey-modal__inputgroup">
                <input
                  class="survey-modal__form__input"
                  name="quantity"
                  type="number"
                  :min="minQuantity"
                  :value="quantity"
                />
                <span class="survey-modal__inputlabel">EMP</span>
              </div>
              <Button variant="secondary" class="survey-modal__button">Submit</Button>
            </form>
          </div>
          <div v-else class="survey-modal__message">
            <h5 class="m-0">
              Thank you for your feedback! <br /><br />
              We will keep you updated on round 4.
            </h5>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.survey-modal {
  &__title {
    line-height: 2rem;
    color: $color-primary;
  }

  &__description {
    max-height: 500px;
    overflow-y: auto;
    margin-bottom: 2rem;

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &__form {
    display: block;

    &__input {
      display: block;
      background-color: #f7f9fc;
      border-radius: 5px;
      border: solid 1px #ddd;
      padding: 1rem;
      transition: border 0.25s;
      width: 100%;

      &:focus {
        background-color: #fff;
      }
    }
  }

  &__inputgroup {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
  }

  &__inputlabel {
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1rem;
  }

  &__button {
    float: right;
  }
}
</style>
